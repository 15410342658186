import apiInstance from "./api";

export const getItemsByOrderId = async (orderId) => { 
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    
  
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  
    try {
      const response = await apiInstance.get(
        `${process.env.REACT_APP_API_URL}/items/all/${orderId}`,
        { headers } 
      );
  
  
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la recupération des items de la commande", error);
      throw error;
    }
  };