import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Checkbox from '@mui/joy/Checkbox';

// MUI Components

import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { Stack } from "@mui/joy";
import { Pagination } from "@mui/material";
import { useMediaQuery } from "@mui/material";


// API
import { getAllRecipes, fetchAllRecipePictures } from '../../../../api/recipesAPI';

//Components
import MobileRecipeCard from "./MobileRecipeCard";

// Toast
import toast from 'react-hot-toast';

// Styles
import "./MobileRecipe.css";

function MobileRecipeList() {
    const { id } = useParams();

    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");
    const navigate = useNavigate();

    const [recipes, setRecipes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showFilters, setShowFilters] = useState(false)
    const [filters, setFilters] = useState({
        entrées: false,
        plats: false,
        desserts: false,
        boissons: false
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = recipes.slice(indexOfFirstItem, indexOfLastItem);

    // Changez la page
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    const fetchRecipes = async () => {
        try {
            const data = await getAllRecipes();
            setRecipes(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchRecipes();
    }, []);




    useEffect(() => {
        const fetchRecipePictures = async () => {
            try {
                const pictures = await fetchAllRecipePictures();
                pictures.forEach(picture => {
                    const recipeIndex = recipes.findIndex(recipe => recipe.id === picture.recipe_id);
                    if (recipeIndex !== -1) {
                        const updatedRecipes = [...recipes];
                        updatedRecipes[recipeIndex].imageUrl = `${process.env.REACT_APP_API_URL}${picture.path}`;
                        setRecipes(updatedRecipes);
                    }
                });
            } catch (error) {
                console.error("Erreur lors de la récupération des images des recettes:", error);
            }
        };

        if (recipes.length > 0) {
            fetchRecipePictures();
        }
    }, [recipes]);

    const handleFilterChange = (filterName) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: !prevFilters[filterName]
        }));
    };

    const filteredRecipes = recipes.filter(recipe => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
            (recipe.name.toLowerCase().includes(searchTermLower) ||
                recipe.description.toLowerCase().includes(searchTermLower)) &&
            (filters.entrées ? recipe.partId === 1 : true) &&
            (filters.plats ? recipe.partId === 2 : true) &&
            (filters.desserts ? recipe.partId === 3 : true) &&
            (filters.boissons ? recipe.partId === 4 : true)
        );
    });

    const indexOfLastRecipe = currentPage * itemsPerPage;
    const indexOfFirstRecipe = indexOfLastRecipe - itemsPerPage;
    const currentRecipes = filteredRecipes.slice(indexOfFirstRecipe, indexOfLastRecipe);

    return (
        <section className="recipe-list-mobile">
            <div className="recipe-mobile-header">
                <h2>Gestion des recettes</h2>
                <Input
                    type="text"
                    placeholder="Rechercher une recette..."
                    variant="outlined"
                    color="neutral"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: 330 }}
                />
                <Button variant="plain" color="success" onClick={() => setShowFilters(!showFilters)}>Filtres</Button>
                {showFilters && (
                    <div className="filters">
                        <Checkbox
                            variant="outlined"
                            size="sm"
                            color="neutral"
                            checked={filters.entrées}
                            onChange={() => handleFilterChange('entrées')}
                            label="Entrées"
                        />
                        <Checkbox
                            variant="outlined"
                            size="sm"
                            color="neutral"
                            checked={filters.plats}
                            onChange={() => handleFilterChange('plats')}
                            label="Plats"
                        />
                        <Checkbox
                            variant="outlined"
                            size="sm"
                            color="neutral"
                            checked={filters.desserts}
                            onChange={() => handleFilterChange('desserts')}
                            label="Desserts"
                        />
                        <Checkbox
                            variant="outlined"
                            size="sm"
                            color="neutral"
                            checked={filters.boissons}
                            onChange={() => handleFilterChange('boissons')}
                            label="Boissons"
                        />
                    </div>
                )}
            </div>
            <div className="mobile-recipe-list">
                {currentRecipes.map(recipe => (
                    <Link key={recipe.id} to={`/recipes/${recipe.id}`}>
                        <MobileRecipeCard recipe={recipe} isTablet={isTablet} />
                    </Link>
                ))}
            </div>

            <div className="pagination">
                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px', marginBottom: "1rem" }}>
                    <Pagination
                        size="small"
                        siblingCount={0}
                        count={Math.ceil(filteredRecipes.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </div>

        </section>
    );
}

export default MobileRecipeList;
