import apiInstance from "./api";

export const fetchSubscription = async (userId) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/subscriptions/list/${userId}`, {
      headers: headers,
    });
    console.log("response fetchSubscription", response)
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations de l'utilisateur:",
      error
    );
    throw error;
  }
};