import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import toast from 'react-hot-toast';

// API
import { fetchAllAllergies, updateCurrentUserInfos } from '../../../../api/userAPI';

// Styles
import './PopupUpdate.css';

function PopupUpdate({ open, onClose, initialData, title, onConfirm = () => { } }) {
    const [allergiesOptions, setAllergiesOptions] = useState([]);
    const [modifiedData, setModifiedData] = useState({ allergies: [] });

    // Fetches all allergies and sets them in state.
    useEffect(() => {
        const loadAllergies = async () => {
            try {
                const allergiesData = await fetchAllAllergies();
                setAllergiesOptions(allergiesData || []);
            } catch (error) {
                console.error("Erreur lors du chargement des allergies:", error);
            }
        };

        loadAllergies();
    }, []);

    useEffect(() => {
        if (initialData && initialData.allergies) {
            setModifiedData({
                ...initialData,
                allergies: initialData.allergies.map(a => a.name)
            });
        }
    }, [initialData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setModifiedData(prev => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const allergyName = e.target.getAttribute('data-name');
        const isChecked = e.target.checked;

        setModifiedData(prev => {
            const newAllergies = isChecked
                ? [...prev.allergies, allergyName]
                : prev.allergies.filter(a => a !== allergyName);
            return { ...prev, allergies: newAllergies };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalData = {
            ...initialData,
            ...modifiedData
        };

        try {
            const updatedUser = await updateCurrentUserInfos(finalData);
            onConfirm(updatedUser);
            toast.success('Vos informations ont été mises à jour');
            onClose();
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
            toast.error('Erreur lors de la mise à jour des informations');
        }
    };

    return (
        <>
            {open && (
                <>
                    <div className="overlay" onClick={onClose}></div>
                    <div className="infos-modal">
                        <h2>{title}</h2>

                        <div className="popup-update-form">
                            <form onSubmit={handleSubmit}>
                                <label>
                                    Prénom:
                                    <input
                                        type="text"
                                        name="firstname"
                                        value={modifiedData.firstname || ''}
                                        onChange={handleInputChange}
                                        className="popup-update-input"
                                        placeholder="Prénom"
                                    />
                                </label>
                                <label>
                                    Nom:
                                    <input
                                        type="text"
                                        name="lastname"
                                        value={modifiedData.lastname || ''}
                                        onChange={handleInputChange}
                                        className="popup-update-input"
                                        placeholder="Nom"
                                    />
                                </label>
                                <label>
                                    Email:
                                    <input
                                        type="email"
                                        name="email"
                                        value={modifiedData.email || ''}
                                        onChange={handleInputChange}
                                        className="popup-update-input"
                                        placeholder="Email"
                                    />
                                </label>
                                {/* Uncomment if you want to use allergies */}
                                {/* <legend className="allergies-legend">Allergies</legend>
                            <div className="update-allergies">
                                {allergiesOptions.map(allergy => (
                                    <div key={allergy.id} className="allergies-fieldset">
                                        <input
                                            type="checkbox"
                                            id={`allergy-${allergy.id}`}
                                            name={allergy.name}
                                            value={allergy.name}
                                            data-id={allergy.id}
                                            data-name={allergy.name}
                                            checked={modifiedData.allergies && modifiedData.allergies.includes(allergy.name)}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label htmlFor={`allergy-${allergy.id}`}>{allergy.name}</label>
                                    </div>
                                ))}
                            </div> */}
                                <div className="popup-actions flex-center">
                                    <button type="submit" className="btn-full btn-sm">Modifier</button>
                                    <button type="button" className="btn-border btn-sm" onClick={onClose}>Annuler</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default PopupUpdate;
