import apiInstance from "./api";
import { fetchCurrentUserOrders } from "./userAPI";

/**
 * Creates an order with the given order data.
 *
 * @param {Object} orderData - The data for the order.
 * @param {string} orderData.userId - The ID of the user placing the order.
 * @param {string} orderData.promoCode - The promo code to apply to the order.
 * @param {number} orderData.price - The total price of the order.
 * @returns {Promise<Object>} - The created order.
 */
export const createOrder = async (orderData) => {
  const token = JSON.parse(localStorage.getItem("user")).accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/orders`, orderData, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de la commande:", error);
    throw error;
  }
};

export const fetchOrderById = async (orderId) => {
  const token = JSON.parse(localStorage.getItem("user")).accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/orders/${orderId}`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de la commande:", error);
    throw error;
  }
};


export const getOrderDetailsByOrderNum = async (readyOrderNum) => {
  try {


    let ordersArray = await fetchCurrentUserOrders();

    // Cherchez cet order_num dans l'array de réponses
    let orderDetails = ordersArray.find(
      (order) => order.order_num === readyOrderNum
    );

    // Affichez les détails de la commande ou faites ce que vous voulez avec
    if (orderDetails) {

      return orderDetails;
    } else {

      return null;
    }
  } catch (error) {
    console.error("Erreur dans getOrderDetailsByOrderNum:", error);
    return null;
  }
};

export const updatePaymentStatus = async () => {
  const token = JSON.parse(localStorage.getItem("user")).accessToken;
  const orderNum = JSON.parse(localStorage.getItem('currentOrder')).order.order_num;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/orders/${orderNum}/payment_status`,
      { payment_status: 1 },
      { headers }
    );

    if (response.data) {
      localStorage.setItem("currentOrder", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du statut de paiement:", error);
    throw error;
  }
};

export const updateOrderStatusbyId = async (orderId) => {
  const token = JSON.parse(localStorage.getItem("user")).accessToken;


  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
      { status: "completed" },
      { headers }
    );

    if (response.data) {
      localStorage.setItem("currentOrder", JSON.stringify(response.data));
    }

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du statut de commande", error);
    throw error;
  }
};

export const updateOrderById = async (orderId, updatedOrder,) => {
  const token = JSON.parse(localStorage.getItem("user")).accessToken;


  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
      updatedOrder,
      { headers }
    );


    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du statut de commande", error);
    throw error;
  }
};


export const deleteOrderById = async (orderId) => {
  const token = JSON.parse(localStorage.getItem("user")).accessToken;


  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.delete(
      `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de la commande", error);
    throw error;
  }
};


