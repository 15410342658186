import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUnavailableItems } from '../../features/cart/cartSlice';

// Elements
import Button from '../elements/button/Button';
import CartItem from './CartItem';

// Styles
import './SideCart.css';

function SideCart({ closeCartDropdown, isMobile }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Global State
    const cart = useSelector((state) => state.cart.items);
    const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled);

    useEffect(() => {
        // Si la pré-commande est désactivée, nettoyez le panier des éléments indisponibles
        if (!preorderEnabled) {
            dispatch(clearUnavailableItems());
        }
    }, [preorderEnabled, dispatch]);

    const total = parseFloat(
        cart.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2)
    );

    const handleCheckout = () => {
        navigate('/order-validation');
        if (isMobile) {
            closeCartDropdown();
        }
    };

    return (
        <div className="side-cart-container">
            <div className="side-cart-items-list">
                <div className="side-cart-title flex-center">
                    <h2>Ma commande</h2>
                </div>
                <div className="side-cart-items">
                    {cart.length === 0 ? (
                        <p className="flex-center">Votre panier est vide.</p>
                    ) : (
                        cart.map((item) => <CartItem key={item.id} item={item} />)
                    )}
                </div>
            </div>

            {cart.length > 0 && (
                <>
                    <div className="side-cart-recap">
                        <div className="side-cart-total-title">
                            <h2>Total de la commande</h2>
                        </div>
                        <div className="side-cart-total">{total} €</div>
                    </div>
                    <div className="side-cart-action">
                        <Button
                            className="btn-border btn-xl"
                            text="Valider la commande"
                            onClick={handleCheckout}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default SideCart;
