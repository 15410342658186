import apiInstance from "./api";
import { getApiHeaders } from "./apiHeaders";



export const sendSummaryMail = async (mailInfos) => {

    try {
        const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/mail/order-summary`, mailInfos, { headers: getApiHeaders() });


        return response.data;
    } catch (error) {
        console.error("Error sending the summary email:", error);
        throw error;
    }
};


export const sendGroupOrderMail = async (mailData) => {

    try {
        const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/mail/group-order-summary`, mailData, { headers: getApiHeaders() });


        return response.data;
    } catch (error) {
        console.error("Error sending the summary email for group order:", error);
        throw error;
    }
};
