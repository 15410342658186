import React, { useState, useEffect } from 'react';
import { FormGroup, FormControlLabel, Switch, Tooltip, Button, Typography } from "@mui/material";
import { IoIosInformationCircle } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

function GroupOrders() {
    const [checked, setChecked] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!checked) {
            if (sessionStorage.getItem('userPostalCode')) {
                sessionStorage.removeItem('userPostalCode');
            }
            navigate('/');
        }
    }, [checked, navigate]);

    const handleSwitchChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <div className="recipes-nav-preorder flex-center">
            <FormGroup className='preoder-actions'>
                <div className='flex-center'>
                    <FormControlLabel
                        control={<Switch checked={checked} onChange={handleSwitchChange} />}
                        label={(
                            <span className='preoder-infos'>
                                <Tooltip
                                    title={
                                        <>
                                            <div className='preorder-tooltip'>
                                                <Typography color="inherit">Les commandes groupées 🔎</Typography>
                                                <em>
                                                    {"Vous n'êtes pas dans la zone de livraison ? Pas de panique. Vous pouvez à présent"}
                                                    <b>{' rejoindre une commande  groupée'}</b> {'sur Mamossapp'}.{' '}
                                                    <br /><br />
                                                    <b>{"Comment cela fonctionne ?"}</b>
                                                    <br />{"Rejoignez une commande groupée pour votre commune, dès que le nombre de participants sera atteint, vous pourrez vous faire livrer votre plat et profiter des délicieux plats Mamossa partout en France"}
                                                    <br /><br />
                                                    <b>{"Comment serais-je tenu au courant ?"}</b>
                                                    <br />{"L'équipe Mamossa vous tiendra au courant par mail de l'évolution de votre commande et du jour de votre livraison"}
                                                </em>
                                            </div>
                                        </>
                                    }
                                >
                                    <Button className='preoder-info-btn'>Commande groupée{" "}
                                        <IoIosInformationCircle />
                                    </Button>
                                </Tooltip>
                            </span>
                        )}
                        className='recipes-nav-switch'
                    />
                </div>
            </FormGroup>
        </div>
    );
}

export default GroupOrders;
