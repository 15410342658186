import apiInstance from "./api";

/**
 * Fetches the current user's information from the server.
 * @returns {Promise} A promise that resolves to an object containing the user's information.
 */
export const fetchCurrentUserInfos = async () => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/user/infos`, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations de l'utilisateur:",
      error
    );
    throw error;
  }
};



/**
 * Fetches the current user's allergies from the server.
 * @returns {Promise} A promise that resolves to an object containing the user's allergies.
 */
export const fetchCurrentUserAllergies = async () => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/user/infos`, {
      headers: headers,
    });

    // Retourner seulement le tableau d'allergies
    return response.data.allergies;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des allergies de l'utilisateur:",
      error
    );
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};



/**
 * Updates the current user's information on the server.
 * @param {Object} userInfo - The updated user information.
 * @returns {Promise} A promise that resolves to the updated user information.
 */
export const updateCurrentUserInfos = async (userInfo) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;


  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(`${process.env.REACT_APP_API_URL}/user/update`, userInfo, {
      headers: headers,
    });

    return response.data;
    
  } catch (error) {
    console.error(
      "Erreur lors de la modification des informations de l'utilisateur:",
      error
    );
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};



/**
 * Fetches the current user's orders from the server.
 * @returns {Promise} A promise that resolves to an array of order objects.
 */
export const fetchCurrentUserOrders = async () => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/orders`, {
      headers: headers,
    });

    if (!response.data || response.data.length === 0) {
      return [];
    }

    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des commandes de l'utilisateur:",
      error
    );
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    return [];
  }
};

/**
 * Fetches all allergies from the server.
 * @returns {Promise} A promise that resolves to an array of allergy objects.
 */
export const fetchAllAllergies = async () => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };
  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/allergy/all`, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des allergies", error);
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};


/**
 * Resets the user's password.
 * @param {string} email - The user's email address.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const resetPasswordMail = async (email) => {

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
  };
  try {
    const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {email}, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la réinitialisation du mot de passe", error);
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};


/**
 * Verify the user.
 * @param {string} userId - The user's ID.
 * @param {string} token - The password reset token.
 * @returns {Promise} A promise that resolves with the response data.
 */
export const verifyUser = async (userId, token) => {
    try {
        const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/auth/reset-password/${userId}/${token}`);
        return response.data;
    } catch (error) {
        throw new Error("Error verifying user.");
    }
};

export const resetPassword = async (userId, token, password, confirmPassword) => {

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
  };
  try {
    const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/auth/reset-password/${userId}/${token}`, {password, confirmPassword}, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la réinitialisation du mot de passe", error);
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};

/**
 * Fetches all users.
 * @returns {Promise} A promise that resolves with the user data.
 */
export const fetchAllUsers = async () => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };
  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/user/get-all`, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des utilisateurs", error);
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};

/**
 * Fetches all orders.
 * @returns {Promise} A promise that resolves with the order data.
 */
export const fetchAllOrders = async () => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };
  try {
    const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/orders/all`, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des commandes", error);
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};

export const deleteUserbyId = async (userId) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "x-access-token": token,
  };
  try {
    const response = await apiInstance.delete(`${process.env.REACT_APP_API_URL}/user/delete/${userId}`, {
      headers: headers,
    });

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'utilisateur", error);
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};


export const updateUserbyId = async (userId, userData) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  if (!token) {
    console.error("Aucun token fourni !");
    return;
  }

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "Content-Type": "application/json",  // Ajout de cet en-tête
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.put(
      `${process.env.REACT_APP_API_URL}/user/update/${userId}`,
      userData,  // Envoi des données dans le corps de la requête
      { headers: headers }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'utilisateur", error);
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};

export const findUserbyId = async (userId) => {
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;

  if (!token) {
    console.error("Aucun token fourni !");
    return;
  }

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "fr",
    "Content-Type": "application/json", 
    "x-access-token": token,
  };

  try {
    const response = await apiInstance.get(
      `${process.env.REACT_APP_API_URL}/user/infos/${userId}`,
      { headers: headers }
    );

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des infos de l'utilisateur", error);
    if (error.response) {
      console.error(
        "Erreur dans la réponse:",
        error.response.status,
        error.response.data
      );
    }
    throw error;
  }
};

