import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//API
import { fetchCurrentUserInfos } from "../../api/userAPI";
import { pikizyCheckInAdmin } from "../../api/pikizyAPI"

//Components
import ClientInfos from './ClientInfos';


//toast
import toast from 'react-hot-toast';

// Styles
import '../../views/admin-dashboard/AdminDashboard.css';

function ClientProfil({ onSelectSection }) {
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des informations de l'utilisateur:",
                    error
                );
            }
        };

        fetchUserInfo();
    }, []);

    const handleOpenFridge = async () => {
        try {
            await pikizyCheckInAdmin();
            toast.success('Frigo ouvert avec succès');
        } catch (error) {
            toast.error("Erreur lors de l'ouverture du frigo");
        }
    };

    const handleSectionClick = (section) => {
        onSelectSection(section);
    };

    return (
        <>
            <ClientInfos userInfo={userInfo} />
        </>
    );
}

export default ClientProfil;
