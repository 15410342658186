// MUI
import { FormControl, InputLabel, Select, MenuItem, Button, TextField } from "@mui/material";

// Icons
import { CiEdit } from "react-icons/ci";

function SharedRecipesDetailsContainer({
    recipe,
    partIdLabels,
}) {
    return (
        <div className="recipes-details-text flex-center">

            <div className="recipes-details-price-container">
                <div className="recipes-details-price">
                    <div className="recipes-details-price-title">
                        <h2>Prix</h2>

                    </div>

                    <>
                        <div className="recipes-details-price">{recipe.price} €</div>
                    </>

                </div>
            </div>

            <div className="recipes-details-description-container">
                <div className="recipes-details-description">
                    <div className="recipes-details-description-title">
                        <h2>Description</h2>

                    </div>

                    <>
                        <div className="recipes-details-description">
                            <p>{recipe.description}</p>
                        </div>
                    </>

                </div>
                <div className="recipes-details-prep_time" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    <div className="recipes-details-prep_time-title" style={{ display: "flex", gap: "1rem" }}>
                        <h2>Temps de préparation en minutes</h2>

                    </div>

                    <div className="recipes-details-prep_time">{recipe.prep_time}</div>

                </div>

                <div className="recipes-details-steps">
                    <div className="recipes-details-steps-title" style={{ display: "flex", gap: "1rem" }}>
                        <h2>Procédé step-by-step</h2>
                    </div>

                    <div className="recipes-details-steps">
                        <p className="recipes-details-steps-content" style={{ textAlign: "justify" }}>{recipe.steps}</p>
                    </div>

                </div>
                <div className="recipes-details-partid-container">
                    <div className="recipes-details-partid">
                        <div className="recipes-details-partid-title">
                            <h2>Type de recette</h2>

                        </div>

                        <>
                            <div className="recipes-details-partid">{partIdLabels[recipe.partId].split("—")[1]}</div>
                        </>

                    </div>
                    <div className="recipes-details-visibility">
                        <div className="recipes-details-visibility-title">
                            <h2>Visibilité</h2>

                        </div>

                        <>
                            <div className="recipes-details-visibility">
                                {recipe.visibility ? "Visible" : "Invisible"}
                            </div>
                        </>

                    </div>
                </div>
            </div>

        </div>
    );
}

export default SharedRecipesDetailsContainer;
