// Checkout.js

import React, { useState, useEffect } from "react";
import { initiateStripePayment } from "../../api/stripeAPI";
import { fetchCurrentUserOrders } from "../../api/userAPI";
import { useDispatch } from "react-redux";
import { resetCart } from "../../features/cart/cartSlice";

function Checkout() {
  const [order_num, setOrderNum] = useState(null);
  const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const dispatch = useDispatch();

  const [paymentUrl, setPaymentUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initiatePayment = async () => {
      setLoading(true);

      try {
        const orders = await fetchCurrentUserOrders();

        if (orders.length > 0) {
          setOrderNum(orders[0].order_num);

          const url = await initiateStripePayment(orders[0].order_num, token);
          setPaymentUrl(url);
        }
      } catch (error) {
        console.error("Erreur lors de l'initialisation du paiement:", error);
      } finally {
        setLoading(false);
      }
    };

    initiatePayment();
  }, [token, dispatch]);

  if (loading) {
    return <p>Initialisation du paiement...</p>;
  }

  if (paymentUrl) {

    localStorage.removeItem("cart");
    dispatch(resetCart());

    window.location.href = paymentUrl;
    return null;
  }

  return <div></div>;
}

export default Checkout;
