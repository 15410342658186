// MUI
import { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, Button, TextField } from "@mui/material";
import Textarea from '@mui/joy/Textarea';
import Switch from '@mui/material/Switch';
// Icons
import { CiEdit } from "react-icons/ci";


function RecipesDetailsContainer({
    recipe,
    isEditing,
    editingField,
    editedFields,
    handleEditClick,
    handleSaveClick,
    handleInputChange,
    partIdLabels,
}) {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.id) {
            setCurrentUser(user.id);
        }
    }, []);

    const isOwner = currentUser === recipe?.user_id;
    const [checked, setChecked] = useState(false);

    const handleToggle = () => {
        const newValue = !checked;
        setChecked(newValue);
        handleInputChange("visibility", newValue);
        handleSaveClick();
    };

    return (
        <div className="recipes-details-text">
            <div className="recipes-details-price-container">
                <div className="recipes-details-price">
                    <div className="recipes-details-price-title">
                        <h2>Prix</h2>
                        {isOwner && (
                            <button onClick={() => handleEditClick("price")} className="edit-btn">
                                <CiEdit className="edit-icon" size={15} />
                            </button>
                        )}
                    </div>
                    {isEditing && editingField === "price" ? (
                        <>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <TextField
                                    id="outlined-multiline-static"
                                    className="recipes-details-description-field"
                                    label="Editer le prix"
                                    color="success"
                                    type="number"
                                    defaultValue={recipe.price}
                                    value={editedFields.price}
                                    onChange={(e) => handleInputChange("price", e.target.value)}
                                />
                            </FormControl>
                            <Button onClick={handleSaveClick} variant="text" color="success">
                                Sauvegarder
                            </Button>
                        </>
                    ) : (
                        <div className="recipes-details-price">{recipe.price} €</div>
                    )}
                </div>
                <div className="recipes-details-barcode">
                    <div className="recipes-details-barcode-title">
                        <h2>Code Barre</h2>
                        {isOwner && (
                            <button onClick={() => handleEditClick("barcode")} className="edit-btn">
                                <CiEdit className="edit-icon" size={15} />
                            </button>
                        )}
                    </div>
                    {isEditing && editingField === "barcode" ? (
                        <>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <TextField
                                    id="outlined-multiline-static"
                                    className="recipes-details-description-field"
                                    label="Editer le code-barres"
                                    color="success"
                                    sx={{ width: "100%" }}
                                    defaultValue={recipe.barcode}
                                    value={editedFields.barcode}
                                    onChange={(e) => handleInputChange("barcode", e.target.value)}
                                />
                            </FormControl>
                            <Button onClick={handleSaveClick} variant="text" color="success">
                                Sauvegarder
                            </Button>
                        </>
                    ) : (
                        <div className="recipes-details-barcode">{recipe.barcode}</div>
                    )}
                </div>
            </div>

            <div className="recipes-details-description-container">
                <div className="recipes-details-description">
                    <div className="recipes-details-description-title">
                        <h2>Description</h2>
                        {isOwner && (
                            <button onClick={() => handleEditClick("description")} className="edit-btn">
                                <CiEdit className="edit-icon" size={15} />
                            </button>
                        )}
                    </div>
                    {isEditing && editingField === "description" ? (
                        <>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <Textarea
                                    id="outlined-multiline-static"
                                    className="recipes-details-description-field"
                                    label="Editer la description"
                                    color="success"
                                    multiline
                                    rows={4}
                                    defaultValue={recipe.description}
                                    value={editedFields.description}
                                    onChange={(e) => handleInputChange("description", e.target.value)}
                                    required
                                    sx={{ mb: 1 }}
                                />
                            </FormControl>
                            <Button onClick={handleSaveClick} variant="text" color="success">
                                Sauvegarder
                            </Button>
                        </>
                    ) : (
                        <div className="recipes-details-description">
                            <p>{recipe.description}</p>
                        </div>
                    )}
                </div>

                <div className="recipes-details-prep_time" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    <div className="recipes-details-prep_time-title" style={{ display: "flex", gap: "1rem" }}>
                        <h2>Temps de préparation en minutes</h2>
                        {isOwner && (
                            <button onClick={() => handleEditClick("prep_time")} className="edit-btn">
                                <CiEdit className="edit-icon" size={15} />
                            </button>
                        )}
                    </div>
                    {isEditing && editingField === "prep_time" ? (
                        <>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <TextField
                                    id="outlined-multiline-static"
                                    className="recipes-details-description-field"
                                    label="Editer "
                                    color="success"
                                    sx={{ width: "100%" }}
                                    defaultValue={recipe.prep_time}
                                    value={editedFields.prep_time}
                                    onChange={(e) => handleInputChange("prep_time", e.target.value)}
                                />
                            </FormControl>
                            <Button onClick={handleSaveClick} variant="text" color="success">
                                Sauvegarder
                            </Button>
                        </>
                    ) : (
                        <div className="recipes-details-prep_time">{recipe.prep_time}</div>
                    )}
                </div>

                <div className="recipes-details-steps">
                    <div className="recipes-details-steps-title" style={{ display: "flex", gap: "1rem" }}>
                        <h2>Procédé step-by-step</h2>
                        {isOwner && (
                            <button onClick={() => handleEditClick("steps")} className="edit-btn">
                                <CiEdit className="edit-icon" size={15} />
                            </button>
                        )}
                    </div>
                    {isEditing && editingField === "steps" ? (
                        <>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <Textarea
                                    id="outlined-multiline-static"
                                    className="recipes-details-steps-field"
                                    label="Editer les étapes"
                                    color="success"
                                    multiline
                                    rows={4}
                                    defaultValue={recipe.steps}
                                    value={editedFields.steps}
                                    onChange={(e) => handleInputChange("steps", e.target.value)}
                                    required
                                    sx={{ mb: 1, width: "100%" }}
                                />
                            </FormControl>
                            <Button onClick={handleSaveClick} variant="text" color="success">
                                Sauvegarder
                            </Button>
                        </>
                    ) : (
                        <div className="recipes-details-steps">
                            <p className="recipes-details-steps-content" style={{ textAlign: "justify" }}>{recipe.steps}</p>
                        </div>
                    )}
                </div>

                <div className="recipes-details-partid-container">
                    <div className="recipes-details-partid">
                        <div className="recipes-details-partid-title">
                            <h2>Type de recette</h2>
                            {isOwner && (
                                <button onClick={() => handleEditClick("partId")} className="edit-btn">
                                    <CiEdit className="edit-icon" size={15} />
                                </button>
                            )}
                        </div>
                        {isEditing && editingField === "partId" ? (
                            <>
                                <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                    <InputLabel id="partId-label" color="success">Editer le nombre de parts</InputLabel>
                                    <Select
                                        labelId="partId-label"
                                        id="partId-select"
                                        value={editedFields.partId}
                                        color="success"
                                        label="Editer le nombre de parts"
                                        onChange={(e) => handleInputChange("partId", e.target.value)}
                                        style={{ backgroundColor: "transparent !important" }}
                                    >
                                        <MenuItem value={1}>Entrées</MenuItem>
                                        <MenuItem value={2}>Plats</MenuItem>
                                        <MenuItem value={3}>Desserts</MenuItem>
                                        <MenuItem value={4}>Boissons</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button onClick={handleSaveClick} variant="text" color="success">
                                    Sauvegarder
                                </Button>
                            </>
                        ) : (
                            <div className="recipes-details-partid">{partIdLabels[recipe.partId].split("—")[1]}</div>
                        )}
                    </div>
                    <div className="recipes-details-visibility">
                        <div className="recipes-details-visibility-title">
                            <h2>Visibilité</h2>
                            {isOwner && (
                                <button onClick={() => handleEditClick("visibility")} className="edit-btn">
                                    <CiEdit className="edit-icon" size={15} />
                                </button>
                            )}
                        </div>
                        {isEditing && editingField === "visibility" ? (
                            <>
                                <Switch
                                    checked={checked}
                                    onChange={handleToggle}
                                    className="edit-btn"
                                    color="success"
                                    inputProps={{ 'aria-label': 'toggle visibility' }}
                                />
                            </>
                        ) : (
                            <div className="recipes-details-visibility">
                                {recipe.visibility ? "Visible" : "Invisible"}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecipesDetailsContainer;
