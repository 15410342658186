import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const localStorageKey = 'preorderEnabled';

const getLocalStorageValue = () => {
  const storedValue = localStorage.getItem(localStorageKey);
  return storedValue ? JSON.parse(storedValue) : false;
};

const getStoredDateTime = () => {
  const storedDate = localStorage.getItem('selectedDate');
  const storedTime = localStorage.getItem('selectedTime');
  return {
    selectedDate: storedDate ? dayjs(storedDate) : null,
    selectedTime: storedTime || null,
    selectedAddress: null,
  };
};

export const setSelectedAddressAsync = (newAddress) => (dispatch) => {
  dispatch(setSelectedAddress(newAddress));
};

const preorderSlice = createSlice({
  name: 'preorder',
  initialState: {
    preorderEnabled: getLocalStorageValue(),
    ...getStoredDateTime(),
    selectedDate: null,
    selectedTime: null,
  },
  reducers: {
    setPreorderEnabled: (state, action) => {
      const newValue = action.payload;
      state.preorderEnabled = newValue;
      localStorage.setItem(localStorageKey, JSON.stringify(newValue));
    },
    setSelectedDate: (state, action) => {
      const newDate = action.payload;
      if (newDate !== null) {
        state.selectedDate = newDate;
        localStorage.setItem('selectedDate', JSON.stringify(newDate));
      }

    },
    setSelectedTime: (state, action) => {
      const newTime = action.payload;
      if (newTime !== null) {
        state.selectedTime = newTime;
        localStorage.setItem('selectedTime', JSON.stringify(newTime));
      }
    },
    setSelectedAddress: (state, action) => {
      const newAddress = action.payload;
      state.selectedAddress = newAddress;
      localStorage.setItem('selectedAddress', newAddress);
    },
  },
});

export const { setPreorderEnabled, setSelectedDate, setSelectedTime, setSelectedAddress } = preorderSlice.actions;
export default preorderSlice.reducer;