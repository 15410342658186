import React, { useState } from 'react';
import Popup from 'reactjs-popup';

import { TextField, MenuItem } from "@mui/material";
import Input from '@mui/joy/Input';


// Styles
import '../popup-product/PopupCreate.css';

function PopupAddress({ trigger, onCreate }) {

    const user = JSON.parse(localStorage.getItem("user"));
    const userId = user ? user.id : null;


    const [formData, setFormData] = useState({
        address: '',
        zip_code: '',
        city: '',
        phone: '',
        additionalInfo: '',
        userId: userId,
    });

    const handleSubmit = async (event, close) => {
        event.preventDefault();
        if (!formData.address || !formData.zip_code || !formData.city) {
            console.error("Veuillez remplir tous les champs obligatoires.");
            return;
        }
        // Call the onCreate function to create the address
        onCreate(formData);
        close();
    };

    return (
        <Popup
            trigger={trigger}
            position="right center"
            modal
            overlayStyle={{ background: 'rgba(0,0,0,0.7)' }}
            contentStyle={{ zIndex: 99999 }}
        >
            {(close) => (
                <div className="popup-create-content">
                    <div className="popup-title">
                        <h1>Nouvelle Adresse</h1>
                    </div>
                    <div className="popup-create-form">
                        <form onSubmit={(e) => handleSubmit(e, close)}>




                            <Input id="outlined-basic" label="Adresse" variant="outlined" type="text"
                                placeholder="Adresse"
                                value={formData.address}
                                onChange={(e) =>
                                    setFormData({ ...formData, address: e.target.value })
                                }
                                sx={{ width: '300px' }}
                                required />


                            <Input id="outlined-basic" label="Code postal" variant="outlined" type="text"
                                placeholder="Code Postal"
                                value={formData.zip_code}
                                onChange={(e) =>
                                    setFormData({ ...formData, zip_code: e.target.value })
                                }
                                sx={{ width: '300px' }}
                                required
                            />
                            <Input id="outlined-basic" label="Ville" variant="outlined" type="text"
                                placeholder="Ville"
                                value={formData.city}
                                onChange={(e) =>
                                    setFormData({ ...formData, city: e.target.value })
                                }
                                sx={{ width: '300px' }}
                                required
                            />

                            <Input id="outlined-basic" label="Téléphone" variant="outlined" type="text"
                                placeholder="Téléphone"
                                value={formData.phone}
                                onChange={(e) =>
                                    setFormData({ ...formData, phone: e.target.value })
                                }
                                sx={{ width: '300px' }}
                                required
                            />
                            <Input id="outlined-basic" label="Autres: Etage, code, bâtiment..." variant="outlined" type="text"
                                placeholder="Etage, code, bâtiment..."
                                value={formData.additionalInfo}
                                onChange={(e) =>
                                    setFormData({ ...formData, additionalInfo: e.target.value })
                                }
                                sx={{ width: '300px' }}
                                multiline
                            />

                            <div className="popup-actions flex-center">

                                <button type="submit" className="btn-full btn-sm">
                                    Créer
                                </button>
                                <button className="btn-border btn-sm" onClick={close}>
                                    Annuler
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default PopupAddress;
