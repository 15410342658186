import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

//Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// API
import { signup, verifyEmail } from "../../api/authAPI";

// Elements
import Button from "../../components/elements/button/Button";

// Styles
import "../login/Login.css";

function Signup() {
  const navigate = useNavigate();

  // Global states
  const { error } = useSelector((state) => state.auth);

  // Local States
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  /**
   * Validates the password according to the following rules:
   * - At least 8 characters
   * - At least one uppercase letter
   * - At least one number
   * - At least one symbol
   * @param {string} password - The password to validate.
   * @returns {boolean} True if the password is valid, false otherwise.
   */
  const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const validatePassword = (password) => {
    const hasEightCharacters = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

    return hasEightCharacters && hasUpperCase && hasNumber && hasSymbol;
  };

  /**
   * Handles the submission of the signup form.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifier si le nom, le prénom ou l'email sont manquants
    if (!lastname || !firstname || !email || !password || !confirmPassword) {
      toast.error("Veuillez remplir tous les champs.");
      return;
    }

    // Vérifier la validité du mot de passe
    if (!validatePassword(password)) {
      toast.error(
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un symbole."
      );
      return;
    }

    // Vérifier le format de l'email
    if (!emailFormat.test(email)) {
      toast.error("Veuillez entrer une adresse email valide.");
      return;
    }

    // Vérifier si les mots de passe correspondent
    if (password !== confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      // Vérifier si l'email est déjà pris
      const emailExists = await verifyEmail(email);
      if (emailExists) {
        toast.error("Erreur lors de l'inscription.");
        return;
      }

      // Ajustez ici l'ordre des paramètres si nécessaire
      const userData = await signup(firstname, lastname, email, password);

      if (userData) {
        navigate("/home");
        toast.success("Votre compte a bien été créé.");
        toast.success("Vous êtes maintenant connecté.");
      } else {
        toast.error("Erreur lors de l'inscription.");
      }
    } catch (error) {
      toast.error("Erreur lors de l'inscription.");
    }
  };

  return (
    <div className="login-container">
      <h1>Inscription</h1>
      <form onSubmit={handleSubmit}>
        <div className="input-content">
          <input
            type="text"
            placeholder="Nom"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            required
          />
        </div>
        <div className="input-content">
          <input
            type="text"
            placeholder="Prénom"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            required
          />
        </div>
        <div className="input-content">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className='input-content'>
          <div className="password-container">
            <input className="password-input" type={showPassword ? "text" : "password"} placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)} required />
            <button
              type="button"
              className="password-toggle-btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOffIcon size={10} style={{ color: "#D5D5D5" }} /> : <VisibilityIcon size={10} style={{ color: "#D5D5D5" }} />}
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
        </div>
        <div className='input-content'>
          <div className="password-container">
            <input
              className="password-input"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirmer le mot de passe"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="password-toggle-btn"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <VisibilityOffIcon size={10} style={{ color: "#D5D5D5" }} /> : <VisibilityIcon size={10} style={{ color: "#D5D5D5" }} />}
            </button>
          </div>
          {error && <p className="error-message">{error}</p>}
        </div>
        <div className="input-content">
          <Button
            className="btn-full"
            type="submit"
            text="S'inscrire"
            onClick={handleSubmit}
          />
        </div>
      </form>
      <div className="create-account-link">
        <p>Déjà un inscrit ?</p>
        <Link to="/login">Se connecter</Link>
      </div>
    </div>
  );
}

export default Signup;
