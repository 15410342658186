import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedAddressAsync } from '../../features/preorder/preorderSlice';
import { fetchUserAddresses, createAddress } from "../../api/addressesAPI";
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel } from "@mui/material";
import PopupAddress from '../elements/popup/popup-address/PopupAddress';
import toast from 'react-hot-toast';

function Addresses(postalCode) {
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [selectedAddressId, setSelectedAddressId] = useState({});

    const [showPopup, setShowPopup] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {

        const getAllAddresses = async () => {
            try {
                const fetchedUserAddresses = await fetchUserAddresses();
                setAddresses(fetchedUserAddresses);
            } catch (error) {
                console.error("Erreur lors de la récupération des addresses:", error);
            }
        };

        getAllAddresses();
    }, []);



    const handleAddressChange = (event) => {
        const newSelectedAddressId = event.target.value;

        setSelectedAddressId(newSelectedAddressId);
        dispatch(setSelectedAddressAsync(newSelectedAddressId));
    };

    const addNewAddressToList = (newAddress) => {
        setAddresses((prevAddresses) => [...prevAddresses, newAddress]);
    };

    return (
        <section className='order-addresses'>
            <div className="order-addresses-title">
                <h2>Où voulez-vous être livré ?</h2>
            </div>
            <div className="order-addresses-actions">
                <PopupAddress
                    trigger={
                        <button className="order-addresses-add btn-add">+ Ajouter une adresse</button>
                    }
                    onCreate={async (formData) => {
                        const newAddress = await createAddress(formData);
                        setShowPopup(false);
                        addNewAddressToList(newAddress);
                    }}
                />
            </div>
            <div className="order-addresses-select">
                <FormControl component="fieldset">
                    <FormLabel component="legend">Sélectionnez une adresse :</FormLabel>
                    <RadioGroup
                        aria-label="addresses"
                        name="addresses"
                        value={selectedAddressId}
                        onChange={handleAddressChange}
                    >
                        {addresses.map((address) => (
                            <FormControlLabel
                                key={address.id}
                                value={address.id.toString()}
                                control={<Radio />}
                                label={address.address + ', ' + address.zip_code + ', ' + address.city}
                                className='address-radio'
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </div>
        </section >
    );
}

export default Addresses;
