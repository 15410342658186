import { useState, useEffect } from "react";

// MUI
import Badge from "@mui/material/Badge";
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import Typography from '@mui/joy/Typography';
import { Stack } from "@mui/joy";
import { Pagination } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';


// API
import { fetchCurrentUserOrders } from "../../api/userAPI";
import { getItemsByOrderId } from "../../api/orderItemsAPI";

//Components
import PopupInfos from "../elements/popup/popup-infos/PopupInfos";


export default function MobileOrderList({
    setUnpaidOrdersExist,
    setUnpaidOrderNum,
    setReadyOrderExist,
    setReadyOrderNum,
}) {
    console.log('setUnpaidOrdersExist', setUnpaidOrdersExist);
    const isMobile = useMediaQuery("(max-width: 600px)");

    const orderFields = [
        { name: 'order_num', label: 'Numéro de commande' },
        { name: 'status', label: 'Statut' },
        { name: 'payment_status', label: 'Statut de paiement' },
        { name: 'userId', label: 'User Id' },
    ];


    // Local States
    const [orders, setOrders] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [openInfosPopup, setOpenInfosPopup] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsByOrder, setItemsByOrder] = useState({});
    const [itemsPerPage] = useState(12);
    const [items, setItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    const [errorItems, setErrorItems] = useState(null);

    // Pagination : Calculez les commandes à afficher
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = orders.slice(indexOfFirstItem, indexOfLastItem);

    // Changez la page
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };



    /**
     * Formats a date string to the format "dd/mm/yyyy hh:mm".
     * @param {string} dateString - The date string to format.
     * @returns {string} The formatted date string.
     */
    function formatDateWithTime(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    /**
     * Fetches the current user's orders and sets them in state.
     */
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user")).accessToken;
        if (token) {
            const getUserOrders = async () => {
                try {
                    const fetchedUserOrders = await fetchCurrentUserOrders(token);
                    setOrders(fetchedUserOrders);

                    // Vérifier si au moins une commande a un statut de paiement à false

                    if (fetchedUserOrders.length > 0 && !fetchedUserOrders[0].payment_status) {

                        setUnpaidOrdersExist(true);
                        setUnpaidOrderNum(fetchedUserOrders[0].order_num);
                    } else {
                        setUnpaidOrdersExist(false);
                    }

                    if (fetchedUserOrders.length > 0 && fetchedUserOrders[0].status === "accepted") {
                        setReadyOrderExist(true);
                        setReadyOrderNum(fetchedUserOrders[0].order_num);
                    } else {
                        setReadyOrderExist(false);
                    }

                } catch (error) {
                    console.error(
                        "Erreur lors de la récupération des commandes:",
                        error
                    );
                }
            };

            getUserOrders();
        }
    }, [setUnpaidOrdersExist, setUnpaidOrderNum, setReadyOrderExist, setReadyOrderNum]);


    const fetchItemsForOrder = async (orderId) => {
        try {
            setLoadingItems(true);
            const itemsData = await getItemsByOrderId(orderId);
            setItemsByOrder((prevItemsByOrder) => ({
                ...prevItemsByOrder,
                [orderId]: itemsData
            }));
            setLoadingItems(false);
        } catch (error) {
            setErrorItems(error);
            setLoadingItems(false);
        }
    };

    /**
     * Formats the price from cents to euros (e.g., 810 -> 8.10).
     * @param {number} priceInCents - The price in cents.
     * @returns {string} The formatted price string.
     */
    function formatPrice(priceInCents) {
        return (priceInCents / 100).toFixed(2);
    }

    // Fonction pour obtenir la classe CSS conditionnelle en fonction de payment_status
    function getPaymentStatusClass(payment_status) {
        return payment_status ? "paid" : "unpaid";
    }

    const handleInfosClick = (order) => {
        setSelectedOrder(order);
        setOpenInfosPopup(true);

    };

    return (
        <div className="client-orders">
            <div className="client-orders-title flex-center">
                <h2>Mon historique des commandes</h2>
            </div>
            <AccordionGroup sx={{ maxWidth: 500, margin: "auto" }}>
                {currentItems.map((order) => (
                    <Accordion
                        sx={{ padding: "1rem 0" }}
                        key={order.id}
                        expanded={expanded === order.id}
                        onChange={() =>
                            setExpanded(expanded === order.id ? false : order.id)
                        }
                        className={order.payment_status ? '' : 'unpaid-background'}
                    >
                        <AccordionSummary
                            style={{ display: "flex", alignItems: "center", padding: isMobile ? " 0 2rem" : "0" }}
                            className={`order-accordion ${getPaymentStatusClass(order.payment_status)}`}
                            classes={{
                                content: "order-summary-content",
                            }}
                        >
                            <Typography className="typography" sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>

                                <Typography sx={{ display: "flex", gap: 1 }}>
                                    {order.payment_status === true ? (
                                        <span style={{ marginLeft: '10px', color: 'green' }}>✔️</span>
                                    ) : order.payment_status ? null : (
                                        <span style={{ marginLeft: '10px', color: 'red' }}>❌</span>
                                    )}
                                    <span>{formatDateWithTime(order.orderedAt).split(" ")[0]}</span>

                                </Typography>
                                <div className="order-info-price">
                                    {order.price ? (order.price / 100).toFixed(2) : "N/A"}€
                                </div>

                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: isMobile ? " 0 2rem" : "0" }}>
                            <div>
                                <p>
                                    <span className="mobile-payment-span">Numéro de commande :</span>{" "}
                                    {order.order_num}
                                </p>
                                <p>
                                    <span className="mobile-payment-span">Statut :</span>{" "}
                                    {order.status ? order.status : "N/A"}
                                </p>
                                <p className="mobile-payment-paragraph">
                                    <span className="mobile-payment-span">Paiement :</span>{" "}
                                    {order.payment_status ? "Payé" : "Echec"}
                                </p>
                                <p>
                                    <span className="mobile-payment-span">Heure :</span>{" "}
                                    {formatDateWithTime(order.orderedAt).split(" ")[1]}
                                </p>
                                <div>

                                    <Typography variant="outlined" color="neutral" onClick={() => handleInfosClick(order)} sx={{ fontWeight: "500", display: "flex", alignItems: "center", marginTop: "1rem" }}>
                                        <SearchIcon sx={{ paddingRight: ".5rem" }} fontSize="medium" />
                                        Voir les items de commandes
                                    </Typography>
                                    <PopupInfos
                                        open={openInfosPopup}
                                        onClose={() => setOpenInfosPopup(false)}
                                        data={selectedOrder}
                                        fields={orderFields}
                                        title="Informations de commande"
                                    />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </AccordionGroup>
            <div className="pagination">
                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px' }}>
                    <Pagination
                        count={Math.ceil(orders.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </div>
        </div >
    );
}