import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

//MUI
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Chip from '@mui/joy/Chip';
import Input from '@mui/joy/Input';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//Icons
import { IoIosInformationCircleOutline } from "react-icons/io";


//API
import { fetchNutriFacts } from '../../../api/nutriFactsAPI'
import { fetchCombinedProductInfoById, fetchProductByRecipeId } from '../../../api/stockAPI'
import { fetchAllRecipePictures } from '../../../api/recipesAPI'
import { updateRecipePortionByMenuId, } from '../../../api/menuRecipesAPI'

//Images
import NutriA from '../../../assets/nutri-score/NutriscoreA.png'
import NutriB from '../../../assets/nutri-score/NutriscoreB.png'
import NutriC from '../../../assets/nutri-score/NutriscoreC.png'
import NutriD from '../../../assets/nutri-score/NutriscoreD.png'
import NutriE from '../../../assets/nutri-score/NutriscoreE.png'

//Components
import PopupRecipeInfos from '../../elements/popup/popup-recipe/PopupRecipeInfos'


function MenuRecipesCard({ recipe, recipesList, updateTotalPrice, onDeleteRecipe }) {

    const { id } = useParams();

    const menuId = id;
    const recipeId = recipe.id


    const recipeInList = recipesList.find(item => item.recipe_id === recipeId);

    const initialPortion = recipeInList ? recipeInList.portions : 4;

    //Local States
    const [nutriFacts, setNutriFacts] = useState([]);
    const [imageUrl, setImageUrl] = useState(undefined);
    const [recipePortions, setRecipePortions] = useState(initialPortion);
    const [productData, setProductData] = useState([])
    const [totalRecipePrice, setTotalRecipePrice] = useState(0);
    const [openIngredientsPopup, setOpenIngredientsPopup] = useState(false);
    const [ingredients, setIngredients] = useState({})

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");


    const fetchNutriInfos = async () => {
        try {
            const data = await fetchNutriFacts(recipeId);
            setNutriFacts(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchNutriInfos(recipeId);
    }, [recipeId]);

    const fetchProductInfos = async () => {
        try {
            const productsDetails = await fetchCombinedProductInfoById(recipeId);
            setProductData(productsDetails);
            updateTotalPrice();
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchProductInfos(recipeId);
    }, [recipeId]);



    useEffect(() => {
        const fetchRecipePictures = async () => {
            try {
                const pictures = await fetchAllRecipePictures();
                if (pictures && pictures.length > 0) {
                    const recipePicture = pictures.find(picture => picture.recipe_id === recipe.id);
                    if (recipePicture) {
                        setImageUrl(`${process.env.REACT_APP_API_URL}${recipePicture.path}`);
                    }
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des images des recettes:", error);
            }
        };

        fetchRecipePictures();
    }, [recipe.id]);

    const fetchIngredients = async () => {
        try {
            const ingredients = await fetchProductByRecipeId(recipe.id);
            setIngredients(ingredients);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {

        fetchIngredients(recipe.id)
    }, [recipe.id]);


    let nutriScoreImage;

    switch (nutriFacts?.nutriscore) {
        case 'A':
            nutriScoreImage = <img src={NutriA} alt="NutriA" style={{ width: 50 }} />;
            break;
        case 'B':
            nutriScoreImage = <img src={NutriB} alt="NutriB" style={{ width: 50 }} />;
            break;
        case 'C':
            nutriScoreImage = <img src={NutriC} alt="NutriC" style={{ width: 50 }} />;
            break;
        case 'D':
            nutriScoreImage = <img src={NutriD} alt="NutriD" style={{ width: 50 }} />;
            break;
        case 'E':
            nutriScoreImage = <img src={NutriE} alt="NutriE" style={{ width: 50 }} />;
            break;
        default:
            nutriScoreImage = null;
    }


    const calculateRecipePrice = (productData) => {
        if (!productData || !productData.productInfos || !productData.recipeProductInfo) {
            return 0;
        }

        return productData.productInfos.reduce((accumulator, productInfo) => {
            const recipeProduct = productData.recipeProductInfo.find(item => item.productId === productInfo.id);
            if (recipeProduct) {
                const productPrice = ((parseFloat(productInfo.price) * recipeProduct.unitQty) / 1000) / 4;
                return accumulator + productPrice;
            } else {
                return accumulator;
            }
        }, 0);
    };

    // Effet pour mettre à jour le prix total de la recette lorsqu'il y a un changement dans les données du produit
    useEffect(() => {
        const newTotalRecipePrice = calculateRecipePrice(productData);
        setTotalRecipePrice(newTotalRecipePrice.toFixed(2));
    }, [productData]);


    const updateRecipePrice = (newPortions) => {
        const newTotalPrice = (calculateRecipePrice(productData) * newPortions).toFixed(2);
        setTotalRecipePrice(newTotalPrice);
    };

    useEffect(() => {
        updateRecipePrice(recipePortions);
        updateTotalPrice();
    }, [recipePortions, productData]);


    const handlePortionsChange = (event) => {
        const newPortions = parseInt(event.target.value, 10);
        setRecipePortions(newPortions);
    };

    const handleSetPortions = async () => {
        try {

            const updatedPortion = {
                portions: recipePortions,
                price: totalRecipePrice
            };
            await updateRecipePortionByMenuId(menuId, recipeId, updatedPortion);
            updateRecipePrice(recipePortions);
            updateTotalPrice();
        } catch (error) {
            console.error("Erreur lors de la mise à jour des portions:", error);
        }
    };


    const handleDeleteRecipeClick = () => {
        onDeleteRecipe(recipe.id);
    };

    const handleInfosClick = () => {
        setOpenIngredientsPopup(true);

    };


    return (


        <div className="menu-recipe-card">
            <Card sx={{ width: isMobile ? '340px' : isTablet ? '380px' : 430 }} orientation={isMobile ? "vertical" : "horizontal"}>

                <AspectRatio ratio={isMobile ? "16/9" : "9/16"} sx={{ width: isMobile ? 305 : 150 }}>
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt={recipe.name}
                            className="card-img"
                            onError={() => console.error(`Erreur lors du chargement de l'image pour la recette ${recipe.name}`)}
                        />
                    ) : (
                        <span>Aucune image disponible</span>
                    )}
                </AspectRatio>
                <CardContent orientation="vertical" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <div>

                        <Typography level="title-lg">
                            <Link
                                to={`/recipes/${recipe.id}`}
                                style={{ textDecoration: 'none', color: '#006d26', fontWeight: 'bold', marginRight: 5 }}
                                className="recipes-title"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {recipe.name}
                            </Link>

                            <IoIosInformationCircleOutline style={{ fontSize: 14 }} onClick={() => handleInfosClick()} className='infos-icon' />
                            <PopupRecipeInfos
                                open={openIngredientsPopup}
                                onClose={() => setOpenIngredientsPopup(false)}
                                ingredients={ingredients}
                                title={`Ingrédient de ${recipe.name}`}
                            />
                        </Typography>
                        <IconButton
                            aria-label="Supprimer la recette"
                            variant="plain"
                            color="neutral"
                            size="sm"
                            sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem', display: isMobile ? "none" : "inherit" }}
                            onClick={handleDeleteRecipeClick}
                        >
                            <HighlightOffIcon color="error" />
                        </IconButton>
                    </div>
                    <div>

                        <Typography fontSize="xs" sx={{ textAlign: "justify" }}>
                            {recipe.description.length > 250 ? `${recipe.description.substring(0, 250)}...` : recipe.description}
                        </Typography>
                    </div>


                    {recipePortions && recipePortions !== 0 && (
                        <div className='card-calories-score' style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <Typography level="body-xs">Prix</Typography>
                                <Typography fontSize="sm" fontWeight="lg">
                                    {totalRecipePrice + "€"}
                                </Typography>
                            </div>
                            {nutriFacts &&
                                <div className="card-calories" style={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                    <div style={{ display: "flex", alignItems: "flex-end", gap: ".5rem" }}>
                                        <Chip variant="soft"
                                            color="primary">
                                            {parseInt(nutriFacts?.calories / nutriFacts?.portions) + " kcal"}
                                        </Chip>
                                        {nutriScoreImage}
                                    </div>
                                </div>
                            }
                        </div>
                    )}

                    <div style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
                        <Typography level="body-xs">Nombre  de portions</Typography>
                        <Input
                            type="number"
                            sx={{ width: 100, fontSize: 12 }}
                            value={recipePortions}
                            placeholder='Portions'
                            onChange={handlePortionsChange}
                            onBlur={() => {
                                handleSetPortions(recipeId);
                                updateTotalPrice();
                                updateRecipePrice(recipePortions);
                            }}
                            slotProps={{
                                input: {
                                    min: 1,
                                    step: 1,
                                },
                            }}
                        />
                    </div>

                    <Typography fontSize="xs" sx={{ textAlign: "center", color: 'red', fontWeight: 'bold', marginTop: "1rem", display: isMobile ? 'block' : 'none' }} onClick={handleDeleteRecipeClick}>
                        Supprimer du menu
                    </Typography>

                </CardContent>
            </Card>
        </div >
    );
}

export default MenuRecipesCard;
