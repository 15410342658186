import { useEffect, useState } from 'react';
import Autocomplete from '@mui/joy/Autocomplete';
import SelectRecipeCard from './SelectRecipeCard';
import './PopupMenu.css';

const PopupMenu = ({ open, onClose, onRecipeSelect, recipes, onAddRecipesToMenu }) => {
    const [selectedRecipes, setSelectedRecipes] = useState([]);

    const getRecipeCategory = (partId) => {
        let categoryName;
        if (partId === 1) {
            categoryName = "ENTRÉES";
        } else if (partId === 2) {
            categoryName = "PLATS";
        } else if (partId === 3) {
            categoryName = "DESSERTS";
        } else if (partId === 4) {
            categoryName = "BOISSONS";
        } else {
            categoryName = "AUTRES";
        }
        return categoryName;
    };

    const optionsWithCategories = recipes
        .sort((a, b) => a.partId - b.partId)
        .map(recipe => {
            return {
                ...recipe,
                category: getRecipeCategory(recipe.partId)
            };
        });

    const handleRecipeSelect = (event, value) => {
        const recipeIds = value.map(recipe => recipe.id);
        setSelectedRecipes(recipeIds);
    };



    const handleAddClick = () => {
        onRecipeSelect(selectedRecipes);
        onAddRecipesToMenu(selectedRecipes);
        onClose();
    };


    return (
        <>
            {open && (
                <>
                    <div className="welcome-overlay" onClick={onClose}></div>
                    <div className='popup-menu'>
                        <div className="popup-menus-title">
                            <h2>Choisir une recette</h2>
                        </div>
                        <div className="popup-menus-content">
                            <Autocomplete
                                fullWidth
                                multiple
                                disablePortal
                                sx={{ width: 350, zIndex: 9999 }}
                                slotProps={{
                                    listbox: {
                                        sx: {
                                            zIndex: 99999,
                                            position: "absolute"
                                        }
                                    }
                                }}
                                id="recipes-box"
                                options={optionsWithCategories}
                                groupBy={(option) => option.category}
                                getOptionLabel={(option) => option.name}
                                onChange={handleRecipeSelect}
                                renderGroup={(params) => (
                                    <div>
                                        <div style={{ backgroundColor: "#006d26", padding: "5px", marginTop: '0', color: '#fff', fontWeight: '500', fontSize: 12 }}>
                                            {params.group}
                                        </div>
                                        <div style={{ marginBottom: '1rem' }}>
                                            {params.children}
                                        </div>

                                    </div>
                                )}
                            />
                            <div className="popup-menu-actions">
                                <button className="btn-full btn-sm" onClick={handleAddClick}>
                                    Ajouter
                                </button>
                                <button
                                    className="btn-border"
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    Fermer
                                </button>
                            </div>

                            <div className="selected-recipes">
                                {selectedRecipes.map(recipeId => {
                                    const selectedRecipe = recipes.find(recipe => recipe.id === recipeId);

                                    return (
                                        <div key={selectedRecipe.id} className="recipe-card">
                                            <SelectRecipeCard selectedRecipe={selectedRecipe} />

                                        </div>
                                    );
                                })}
                            </div>


                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default PopupMenu;
