import { useState } from "react";

// Elements
import Button from "../elements/button/Button";
import PopupUpdate from "../elements/popup/popup-update/PopupUpdate";
import ClientAllergies from "./ClientAllergies";

import ProfilPicture from "../../assets/profil/profil-picture.webp"

//MUI
import Avatar from '@mui/joy/Avatar';



function ClientInfos({ userInfo }) {
  const [userInfos, setUserInfos] = useState(userInfo);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  /**
   * Formats a date string to the format "dd/mm/yyyy".
   * @param {string} dateString - The date string to format.
   * @returns {string} The formatted date string.
   */
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois sont indexés à partir de 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  /**
   * Handles the confirmation of the update of user information.
   * @param {Object} updatedData - The updated user information.
   */
  function handleUpdateConfirm(updatedData) {
    setUserInfos(updatedData);
    setOpenUpdatePopup(false); // Close the popup after update
  }

  const handleInfosClick = (userInfo) => {
    setSelectedUser(userInfo);
    setOpenUpdatePopup(true);
  };

  const profilFields = [
    { name: 'firstname', label: 'Prénom' },
    { name: 'lastname', label: 'Nom' },
    { name: 'email', label: 'Email' },
  ];

  return (
    <div className="client-infos-container container">
      <div className="picture-mobile flex-center">

        <div className="client-infos-picture-mobile">
          <img
            src={ProfilPicture}
            alt="Client Profil"
            className="client-picture"
          />
        </div>

      </div>


      <div className="client-infos  flex-center">
        <div className="client-infos-picture">
          <img
            src={ProfilPicture}
            alt="Client Profil"
            className="client-picture"
          />
        </div>

      </div>

      <div className="client-infos-perso flex-center">
        <h2>Mes Informations</h2>
        <div className="client-full-name">
          {userInfo.firstname} {userInfo.lastname}
        </div>
        <div className="client-mail">{userInfo.email}</div>
        <div className="client-age">{userInfo.age} ans</div>
        <div className="client-inscription">
          Inscrit depuis le {formatDate(userInfo.createdAt)}
        </div>
      </div>


      <div className="client-infos-allergies">
        <div className="client-allergies-title">
          <h2 className="flex-center">Mes allergies</h2>
        </div>
        <ClientAllergies />
      </div>



      <div className="client-infos-action flex-center">
        <Button
          className="btn-border btn-ms"
          text="Modifier mes informations"
          onClick={() => handleInfosClick(userInfo)}
        />
        {openUpdatePopup && (
          <PopupUpdate
            open={openUpdatePopup}
            onClose={() => setOpenUpdatePopup(false)}
            initialData={selectedUser}
            fields={profilFields}
            title="Modifier le profil"
            onConfirm={handleUpdateConfirm}
          />
        )}
      </div>
    </div>
  );
}

export default ClientInfos;
