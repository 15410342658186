import apiInstance from "./api";
import {fetchCurrentUserInfos} from "./userAPI"



export const authenticatePaygreen = async () => {
    const token = JSON.parse(localStorage.getItem("user")).accessToken;
  
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": "fr",
      "x-access-token": token,
    };
  
    try {
      const response = await apiInstance.post(
        `${process.env.REACT_APP_API_URL}/paygreen/auth`,
        { headers: headers }
      );
  
      return response.data;
    } catch (error) {
      console.error("Erreur lors de l'authentification Paygreen", error);
      throw error;
    }
  };


  export const createInstantPayment = async (amount, description, orderNum ) => {
    const token = JSON.parse(localStorage.getItem("user")).accessToken;
  
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": "fr",
      "x-access-token": token,
      "Content-Type": "application/json",
    };
  
    try {
      // Authentification Paygreen
      const paygreenAuth = await authenticatePaygreen();

      const userResponse = await apiInstance.get(`${process.env.REACT_APP_API_URL}/user/infos`, { headers: headers });


  
      // Appel à l'API pour créer le paiement instantané
      const response = await apiInstance.post(
        `${process.env.REACT_APP_API_URL}/paygreen/createInstantPayment`,
        {
            buyerInfo: {
                first_name: userResponse.data.firstname,
                last_name: userResponse.data.lastname,
                email: userResponse.data.email,
                orderNum: orderNum, 
            },
            amount : amount,
            description : description,
        },
        { headers: headers }
      );

      return response.data;
    } catch (error) {
      console.error("Erreur lors de la création du paiement instantané:", error);
      throw error;
    }
  };


