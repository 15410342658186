import React from 'react';
import { useSelector } from 'react-redux';
import PreOrder from './PreOrder';
import GroupOrders from './GoupOrders';

// Styles
import './RecipesNav.css';

function RecipesNav() {
    const isInDeliveryZone = useSelector(state => state.deliveryZone.isInDeliveryZone);

    return (
        <>
            <nav className="recipes-nav">
                <div className="recipes-nav-links">
                    <a href="#Entrée">Entrées</a>
                    <a href="#Plats">Plats</a>
                    <a href="#Dessert">Desserts</a>
                    <a href="#Boisson">Boissons</a>
                </div>
            </nav>
            {isInDeliveryZone ? <PreOrder /> : <GroupOrders />}
        </>
    );
}

export default RecipesNav;
