import React, { useState, useEffect } from "react";

//MUI
import {
    styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Badge,
    InputAdornment
} from "@mui/material";

//Icons
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CiEdit } from 'react-icons/ci';
import { TiDeleteOutline } from 'react-icons/ti';
import SearchIcon from '@mui/icons-material/Search';

//Components
import PopupCreate from "../../elements/popup/popup-product/PopupCreate";
import PopupEditProduct from "../../elements/popup/popup-product/PopupEditProduct";
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import ButtonComponent from "../../elements/button/Button";

//toast
import toast from 'react-hot-toast';

//API
import { fetchProduct, createProduct, deleteProductById, updateProductById } from '../../../api/stockAPI';
import { linkProductToAllergy } from "../../../api/productAllergyAPI"

//CSS
import '../../../views/admin-dashboard/stock/Stock.css'


const ProductTable = () => {
    const [stocks, setStocks] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState("");
    const productPerPage = 8;

    useEffect(() => {
        const fetchStocks = async () => {
            try {
                const data = await fetchProduct();
                setStocks(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des stocks", error);
            }
        };

        fetchStocks();
    }, []);

    useEffect(() => {
        const productsToSort = [...stocks];
        productsToSort.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setSortedProducts(productsToSort);
    }, [sortConfig, stocks]);

    const filteredProducts = sortedProducts.filter(product =>
        (product.id?.toString() || "").includes(searchTerm) ||
        (product.name?.toLowerCase() || "").includes(searchTerm) ||
        (product.price?.toString() || "").includes(searchTerm) ||
        (product.unitQty?.toString() || "").includes(searchTerm) ||
        (product.unitType?.toLowerCase() || "").includes(searchTerm) ||
        (product.totalQuantity?.toString() || "").includes(searchTerm)
    );

    const handleSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredProducts.length / productPerPage)) {
            setCurrentPage(prev => prev + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    // Create new product in DB
    const handleCreateProduct = async (formData, selectedAllergen) => {
        try {
            const newProductResponse = await createProduct(formData);

            if (newProductResponse && newProductResponse.id) {
                toast.success('Produit créé avec succès');
                setIsPopupOpen(false);
                setStocks((prevStocks) => [...prevStocks, newProductResponse]);

                // Appel de linkProductToAllergy après la création du produit
                if (selectedAllergen) {
                    const productAllergyData = {
                        productId: newProductResponse.id,
                        allergyId: selectedAllergen
                    }
                    await linkProductToAllergy(productAllergyData);
                    toast.success('Allergène lié au produit avec succès');
                }

                // Après avoir ajouté le nouveau produit, récupérez à nouveau les produits
                fetchProduct();
            } else {
                toast.error('Erreur lors de la création du produit');
            }
        } catch (error) {
            toast.error('Erreur lors de la création du produit');
            console.error("Erreur lors de la création du produit", error);
        }
    };



    // Fields for product data
    const productFields = [
        { name: 'name', label: 'Nom du produit' },
        { name: 'price', label: 'Prix' },
        { name: 'totalQuantity', label: `Quantité Totale` },
        { name: 'category', label: `Catégorie` },
        { name: 'label', label: `Label` },

    ];


    const handleEditClick = (product) => {
        setSelectedProduct(product);
        setOpenEditPopup(true);
    };


    const handleSaveProduct = async (updatedProduct, id) => {
        try {
            // Mise à jour en local
            const updatedProducts = stocks.map(product =>
                product.id === updatedProduct.id ? updatedProduct : product
            );
            setStocks(updatedProducts);

            // Mise à jour côté serveur
            await updateProductById(updatedProduct.id, updatedProduct);
            toast.success('Produit mis à jour avec succès');
            setOpenEditPopup(false);
        } catch (error) {
            console.error("Erreur lors de la mise à jour du produit", error);
            toast.error('Erreur lors de la mise à jour du produit');
        }

        // Fetch les données après la mise à jour
        fetchProduct();
    };


    // This function handles the deletion of a recipe
    const handleConfirmDelete = async (id) => {
        try {
            await deleteProductById(id);
            toast.success('Produit supprimée avec succès');
            const updatedProducts = stocks.filter(product => product.id !== id);
            setStocks(updatedProducts);
        } catch (error) {
            console.error('Erreur lors de la suppression de la recette :', error);
            toast.error('Erreur lors de la suppression de la recette');
        }
        fetchProduct();
    };


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        "&.MuiTableCell-head": {
            backgroundColor: "rgb(50, 89, 62)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            cursor: "pointer",
        },
        "&.MuiTableCell-body": {
            fontSize: 12,
        },
    }));



    return (

        <>
            <div className="flex-center" style={{ "display": "flex", "marginBottom": "1rem" }}>

                <input
                    placeholder="Rechercher"
                    variant="outlined"
                    color="success"
                    className="search-bar"
                    fullWidth
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" >
                                <SearchIcon style={{ "fontSize": "15px" }} />
                            </InputAdornment>

                        ),
                    }}
                    style={{ margin: '10px', width: '99%', backgroundColor: "transparent" }}
                />
            </div>
            <div style={{ textAlign: "right", marginRight: ".2rem" }}>
                <PopupCreate
                    trigger={
                        <ButtonComponent
                            className="btn-add btn-add-product-table"
                            type="button"
                            text="+ Ajouter un produit"
                            isOpen={isPopupOpen}

                        />
                    }

                    onClose={() => setIsPopupOpen(false)}
                    onCreate={handleCreateProduct}
                    title="Ajouter un produit"
                    message="Message de confirmation"
                />
            </div>
            <TableContainer component={Paper}>
                <Table style={{ minWidth: "850px" }} aria-label="products table">
                    <TableHead>
                        <TableRow>
                            {[
                                { key: 'id', label: 'ID' },
                                { key: 'name', label: 'Nom' },
                                { key: 'price', label: 'Prix TTC' },
                                { key: 'priceHT', label: 'Prix HT' },
                                { key: 'unitType', label: 'Unités' },
                                { key: 'totalQuantity', label: 'Quantité' },
                                { key: 'category', label: 'Catégorie' },
                                { key: 'label', label: 'Label' },
                                { key: 'createdAt', label: 'Ajouté le' },
                                { key: 'update', label: '' },
                                { key: 'delete', label: '' },
                            ].map(column => (
                                <StyledTableCell key={column.key}>
                                    <IconButton size="small" onClick={() => handleSort(column.key)} style={{ color: 'white', fontSize: 13 }}>
                                        {column.label}
                                        {sortConfig.key === column.key && (sortConfig.direction === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
                                    </IconButton>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredProducts.slice((currentPage - 1) * productPerPage, currentPage * productPerPage).map(product => (
                            <TableRow key={product.id}>
                                <TableCell style={{ fontSize: 13 }}>{product.id}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>{product.name}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>{product.price + " €"}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>{(product.price / 1.055).toFixed(2) + " €"}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>{product.unitType}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>{product.totalQuantity}</TableCell>
                                <TableCell style={{ fontSize: 13 }}>{product?.category} </TableCell>
                                <TableCell style={{ fontSize: 13 }}>
                                    <div style={{ display: "flex", gap: ".5rem", alignItems: "center", justifyContent: "center" }}>
                                        <Badge badgeContent={product?.label} color="info" />
                                    </div>
                                </TableCell>
                                <TableCell style={{ fontSize: 13 }}>
                                    {new Date(product.createdAt).toLocaleString('fr-FR', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    })}
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEditClick(product)}>
                                        <CiEdit size={15} />
                                    </IconButton>

                                    <PopupEditProduct
                                        open={openEditPopup}
                                        onClose={() => setOpenEditPopup(false)}
                                        data={selectedProduct}
                                        onSave={handleSaveProduct}
                                    />
                                </TableCell>
                                <TableCell>
                                    <PopupConfirm
                                        trigger={
                                            <TiDeleteOutline
                                                size={20}
                                                className='item-delete-icon'
                                                style={{ "color": "red" }}
                                            />
                                        }
                                        onConfirm={() => handleConfirmDelete(product.id)}
                                        message={`Êtes-vous sûr de vouloir supprimer ${product.name} du stock ?`}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "2rem" }}>
                <Button style={{ border: "green 1px solid", color: "green", padding: ".5rem" }} disabled={currentPage === 1} onClick={handlePreviousPage}>
                    <ArrowBackIosNewIcon style={{ "fontSize": "15px" }} />
                </Button>
                <span>Page {currentPage}</span>
                <Button style={{ border: "green 1px solid", color: "green", padding: ".5rem" }} disabled={currentPage === Math.ceil(filteredProducts.length / productPerPage)} onClick={handleNextPage}>
                    <ArrowForwardIosIcon style={{ "fontSize": "15px" }} />
                </Button>
            </div>
        </>
    );
};

export default ProductTable;
