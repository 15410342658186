// StripeAPI.js
import apiInstance from "./api";

// ... (les autres fonctions existantes)

/**
 * Initializes a Stripe payment.
 * @param {string} order_num - The order number.
 * @param {string} token - The user's access token.
 * @returns {Promise} A promise that resolves to the Stripe session URL.
 */
export const initiateStripePayment = async (order_num, token) => {
    const headers = {
        "Content-Type": "application/json",
        "x-access-token": token
    };

    try {
        const response = await apiInstance.post(`${process.env.REACT_APP_API_URL}/stripe/create-payment-intent`, { order_num }, { headers });
        return response.data.url;
    } catch (error) {
        console.error("Erreur lors de l'initialisation du paiement:", error);
        throw error;
    }
};
