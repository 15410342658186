import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart } from '../../features/cart/cartSlice';
import Pic from '../../assets/recipes/bowl.avif';
import { PiMinus } from 'react-icons/pi';
import { PiPlus } from 'react-icons/pi';
import { TiDeleteOutline } from 'react-icons/ti';
import toast from "react-hot-toast";
import PopupConfirm from '../../components/elements/popup/popup-confirm/PopupConfirm';

function CartItem({ item }) {
    const deliveryZones = ['93220', '93340', '93250', '93370', '93330'];
    const userPostalCode = sessionStorage?.getItem('userPostalCode');
    const isInDeliveryZone = deliveryZones.includes(userPostalCode);

    const imageUrl = item && item.pictures && item.pictures.length > 0
        ? `${process.env.REACT_APP_API_URL}${item.pictures[0].path}`
        : Pic;

    const dispatch = useDispatch();
    const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled);

    const handleIncrement = () => {
        // Si preorderEnabled est activé, ajoutez au panier sans tenir compte de qty_available
        if (!isInDeliveryZone || preorderEnabled || item.quantity < item.qty_available) {
            dispatch(incrementQuantity(item));
        } else if (preorderEnabled) {
            dispatch(incrementQuantity(item));
        } else {
            toast.error(`La quantité maximale de ${item.name} a été atteinte.`);
        }
    };

    const handleDecrement = () => {
        if (item.quantity > 1) {
            dispatch(decrementQuantity(item));
        }
    };

    const handleDelete = () => {
        dispatch(removeFromCart(item));
    };

    return (
        <div className="cart-item-container">
            <div className="item-text">
                <div className="item-title">
                    <h3>{item.name}</h3>
                </div>
                <div className="item-actions">
                    <div className="item-price">{item.price}€</div>
                    <div className="item-quantity">
                        <PiMinus className='item-qty-icon' onClick={handleDecrement} />
                        {item.quantity}
                        <PiPlus className='item-qty-icon' onClick={handleIncrement} />
                    </div>
                    <div className="item delete">
                        <PopupConfirm
                            trigger={<TiDeleteOutline size={20} className='item-delete-icon' />}
                            onConfirm={handleDelete}
                            message={`Êtes-vous sûr de vouloir supprimer ${item.name} de votre panier ?`}
                        />
                    </div>
                </div>
            </div>

            <div className="item-img-container">
                <img src={imageUrl} alt={item.name} className='item-img' />
            </div>
        </div>
    );
}

export default CartItem;
