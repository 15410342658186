import apiInstance from "./api";

export const fetchAllAllergies = async () => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
  
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-access-token": token,
    };
  
    try {
      const response = await apiInstance.get(`${process.env.REACT_APP_API_URL}/allergy/all`, {
        headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error while fetching all allergies", error);
      throw error;
    }
  };