import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionExists: false,
  subscriptionInfos: {},
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionExists: (state, action) => {
      state.subscriptionExists = action.payload.exists;
      state.subscriptionInfos = action.payload.subscriptionInfos || {};
    },
    updateSubscriptionInfos: (state, action) => {
      state.subscriptionInfos = action.payload;
    },
    resetSubscription: (state) => {
      state.subscriptionExists = false;
      state.subscriptionInfos = {};
    },
  },
});

export const { setSubscriptionExists, updateSubscriptionInfos, resetSubscription } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
