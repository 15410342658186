import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

//Redux
import store from "./store";

//Routes
import AppRoutes from "./routes/AppRoutes";

//Components
import Navbar from "./components/navbar/Navbar";
import ToasterConfig from "./components/toaster/toasterConfig";

//Style
import "./App.css";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <div className="app container">
          <AppRoutes />
        </div>
      </Router>
      <ToasterConfig />
      </>
  );
}

export default App;
