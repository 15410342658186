import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Checkbox from '@mui/joy/Checkbox';

// MUI Components
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { Stack } from "@mui/joy";
import { Pagination } from "@mui/material";
import { useMediaQuery } from "@mui/material";

// Components
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import PopupRecipe from '../../elements/popup/popup-recipe/PopupRecipe';
import ButtonComponent from '../../elements/button/Button';

// API
import { getAllRecipes, fetchAllRecipePictures, createRecipe, uploadRecipeImage, fetchRecipeByUserId } from '../../../api/recipesAPI';

// Components
import MobileRecipeCard from "./mobile/MobileRecipeCard";

// Toast
import toast from 'react-hot-toast';

// Styles
import "./mobile/MobileRecipe.css";

function RecipesTable() {
    const { id } = useParams();

    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");
    const isDesktop = useMediaQuery("(min-width: 1024px)");
    const navigate = useNavigate();

    const [recipes, setRecipes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        entrées: false,
        plats: false,
        desserts: false,
        boissons: false
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = recipes.slice(indexOfFirstItem, indexOfLastItem);

    // Changez la page
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const fetchRecipesWithPictures = async () => {
        try {
            const data = await fetchRecipeByUserId();
            const pictures = await fetchAllRecipePictures();
            const recipesWithImages = data.map(recipe => {
                const picture = pictures.find(pic => pic.recipe_id === recipe.id);
                if (picture) {
                    recipe.imageUrl = `${process.env.REACT_APP_API_URL}${picture.path}`;
                }
                return recipe;
            });
            setRecipes(recipesWithImages);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchRecipesWithPictures();
    }, []);

    const handleFilterChange = (filterName) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filterName]: !prevFilters[filterName]
        }));
    };

    const filteredRecipes = recipes.filter(recipe => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
            (recipe.name.toLowerCase().includes(searchTermLower) ||
                recipe.description.toLowerCase().includes(searchTermLower)) &&
            (filters.entrées ? recipe.partId === 1 : true) &&
            (filters.plats ? recipe.partId === 2 : true) &&
            (filters.desserts ? recipe.partId === 3 : true) &&
            (filters.boissons ? recipe.partId === 4 : true)
        );
    });

    const indexOfLastRecipe = currentPage * itemsPerPage;
    const indexOfFirstRecipe = indexOfLastRecipe - itemsPerPage;
    const currentRecipes = filteredRecipes.slice(indexOfFirstRecipe, indexOfLastRecipe);

    const handleCreateRecipe = async (formData) => {
        // Vérification basique des données du formulaire
        if (!formData.name || !formData.description || !formData.price || !formData.image) {
            toast.error("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        try {
            // Création de la recette via l'API
            const newRecipeResponse = await createRecipe(
                formData.name,
                formData.description,
                formData.price,
                formData.barcode,
                formData.partId,
                formData.prep_time,
                formData.steps,
                formData.visibility === '1' ? true : false,
                formData.user_id
            );

            if (newRecipeResponse && newRecipeResponse.data && newRecipeResponse.data.id) {
                const recipeId = newRecipeResponse.data.id;
                // Upload de l'image de la recette
                await uploadRecipeImage(recipeId, formData.image);

                toast.success('Recette créée avec succès');

                // Mise à jour de la liste des recettes
                fetchRecipesWithPictures();
                setIsCreatePopupOpen(false); // Fermeture du popup
            } else {
                toast.error('Erreur lors de la création de la recette. Réponse inattendue de l\'API.');
            }
        } catch (error) {
            console.error("Erreur lors de la création de la recette", error);
            toast.error('Erreur lors de la création de la recette. Veuillez réessayer.');
        }
    };

    return (
        <section className="recipe-list-mobile">
            <div className="recipe-mobile-header">
                <Input
                    type="text"
                    placeholder="Rechercher une recette..."
                    variant="outlined"
                    color="neutral"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: 530 }}
                />
                <Button variant="plain" color="success" onClick={() => setShowFilters(!showFilters)}>Filtres</Button>
                {showFilters && (
                    <div className="filters">
                        <Checkbox
                            variant="outlined"
                            size="sm"
                            color="neutral"
                            checked={filters.entrées}
                            onChange={() => handleFilterChange('entrées')}
                            label="Entrées"
                        />
                        <Checkbox
                            variant="outlined"
                            size="sm"
                            color="neutral"
                            checked={filters.plats}
                            onChange={() => handleFilterChange('plats')}
                            label="Plats"
                        />
                        <Checkbox
                            variant="outlined"
                            size="sm"
                            color="neutral"
                            checked={filters.desserts}
                            onChange={() => handleFilterChange('desserts')}
                            label="Desserts"
                        />
                        <Checkbox
                            variant="outlined"
                            size="sm"
                            color="neutral"
                            checked={filters.boissons}
                            onChange={() => handleFilterChange('boissons')}
                            label="Boissons"
                        />
                    </div>
                )}
            </div>
            <div style={{ textAlign: "right", marginRight: ".2rem", marginTop: "2rem" }}>
                <PopupRecipe
                    trigger={
                        <ButtonComponent
                            className="btn-add btn-add-product-table"
                            type="button"
                            text="+ Ajouter une recette"
                            isOpen={isCreatePopupOpen}
                        />
                    }
                    onClose={() => setIsCreatePopupOpen(false)}
                    onCreate={handleCreateRecipe}
                    title="Ajouter une recette"
                    message="Message de confirmation"
                />
            </div>
            <div className="recipe-list flex-center">
                {currentRecipes.map(recipe => (
                    <a key={recipe.id} href={`/recipes/${recipe.id}`} target="_blank" rel="noopener noreferrer">
                        <MobileRecipeCard recipe={recipe} isTablet={isTablet} isDesktop={isDesktop} />
                    </a>
                ))}
            </div>

            <div className="pagination">
                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px', marginBottom: "1rem" }}>
                    <Pagination
                        size="small"
                        siblingCount={0}
                        count={Math.ceil(filteredRecipes.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </div>
        </section>
    );
}

export default RecipesTable;
