import { useDispatch, useSelector } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart } from '../../features/cart/cartSlice';

//Icons
import { PiMinus } from 'react-icons/pi';
import { PiPlus } from 'react-icons/pi';

// MUI Components
import { IoIosInformationCircleOutline } from 'react-icons/io';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Chip from '@mui/joy/Chip';
import Input from '@mui/joy/Input';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Images
import ItemImg from '../../assets/recipes/bowl.avif'
import toast from 'react-hot-toast';

function OrderItem({ item }) {

    const imageUrl = item && item.pictures && item.pictures.length > 0
        ? `${process.env.REACT_APP_API_URL}${item.pictures[0].path}`
        : ItemImg;


    const dispatch = useDispatch();
    const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled);

    const handleIncrement = () => {
        //dispatch(incrementQuantity(item));

        if (!preorderEnabled && item.quantity < item.qty_available) {
            dispatch(incrementQuantity(item));
        } else if (preorderEnabled) {
            dispatch(incrementQuantity(item));
        } else {
            toast.error(`La quantité maximale de ${item.name} a été atteinte.`);
        }
    };

    const handleDecrement = () => {
        if (item.quantity > 1) {
            dispatch(decrementQuantity(item));
        } else {
            dispatch(removeFromCart(item));
        }
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");



    return (
        <div className="order-item-container">
            <Card sx={{ width: isMobile ? 320 : isTablet ? "600px" : 600, padding: 0, marginBottom: 2 }} orientation="horizontal">
                <AspectRatio ratio="1" sx={{ width: isMobile ? 90 : 160, height: "100%", borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt={item.name}
                            className="card-img"
                            onError={() => console.error(`Erreur lors du chargement de l'image pour la recette ${item.name}`)}
                        />
                    ) : (
                        <span>Aucune image disponible</span>
                    )}
                </AspectRatio>
                <CardContent orientation="vertical" sx={{ display: "flex", justifyContent: "space-between", padding: 1 }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <Typography level="title-lg">
                                {item.name}
                            </Typography>
                        </div>
                        <div>
                            <Typography fontSize="sm" fontWeight="lg" sx={{ paddingRight: 2 }}>
                                {item.price + "€"}
                            </Typography>
                        </div>
                    </div>
                    {isMobile ? (
                        <div></div>
                    ) : (
                        <Typography level="body-xs" sx={{ marginBottom: 1, textAlign: "justify" }}>
                            {item.description.length > 200 ? `${item.description.substring(0, 150)}...` : item.description}
                        </Typography>
                    )}


                    <div className="order-item-quantity" style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", margin: 0 }}>
                        <div>
                            <Typography level="body-sm">Quantité :</Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "flex-end" }}>
                            <PiMinus className='item-qty-icon' size={10} onClick={handleDecrement} />
                            <Typography className="item-qty" level="body-sm">{item.quantity}</Typography>
                            <PiPlus className='item-qty-icon' size={10} onClick={handleIncrement} />
                        </div>
                    </div>
                </CardContent>
            </Card>

        </div >
    );
}

export default OrderItem;
