import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// MUI
import { styled, Card, CardContent, Typography, Chip, IconButton } from "@mui/material";
import { Stack } from "@mui/joy";
import { Pagination } from "@mui/material";
import { TiDeleteOutline } from 'react-icons/ti';
import ShareIcon from '@mui/icons-material/Share';
import { useMediaQuery } from "@mui/material";

// Components
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import PopupAddMenu from '../../elements/popup/popup-menus/PopupAddMenu';
import ButtonComponent from '../../elements/button/Button';

// API 
import { createMenu, deleteMenuById, getMenuByUser } from '../../../api/menuAPI';

// toast
import toast from 'react-hot-toast';

// dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export default function MobileMenuList() {

    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    // Local states
    const [searchTerm, setSearchTerm] = useState("");
    const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
    const [menusData, setMenusData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    useEffect(() => {
        const fetchAllMenus = async () => {
            try {
                const data = await getMenuByUser();
                setMenusData(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des menus:", error);
                toast.error('Erreur lors de la récupération des menus');
            }
        };
        fetchAllMenus();
    }, []);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = menusData.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    // Handle value's changes in search input
    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    };

    const handleCreateMenu = async (formData) => {
        try {
            const newMenuResponse = await createMenu(formData);
            toast.success('Menu créé avec succès');
            setMenusData(await getMenuByUser());
            setIsCreatePopupOpen(false);
        } catch (error) {
            toast.error('Erreur lors de la création du menu');
            console.error("Erreur lors de la création du menu", error);
        }
    };

    // Function to delete a menu
    const handleConfirmDelete = async (id) => {
        try {
            await deleteMenuById(id);
            toast.success('Menu supprimé avec succès');
            setMenusData(menusData.filter(menu => menu.id !== id));
        } catch (error) {
            console.error('Erreur lors de la suppression du menu :', error);
            toast.error('Erreur lors de la suppression du menu');
        }
        getMenuByUser();
    };

    //Function to copy shared menh link
    const handleShareClick = async (id) => {
        const url = `${window.location.origin}/menu/${id}/shared`;
        try {
            await navigator.clipboard.writeText(url);
            toast.success('Lien copié dans le presse-papier');
        } catch (err) {
            console.error('Failed to copy: ', err);
            toast.error('Erreur lors de la génération du lien de partage');
        }
    };


    // Function to render each menu card
    const renderMenuCards = () => {
        return currentItems
            .filter(menu =>
                (menu.id?.toString() || "").includes(searchTerm) ||
                menu.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                menu.name?.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map(menu => (
                <Card key={menu.id} style={{ margin: '10px', width: isTablet ? 430 : 330 }}>
                    <CardContent style={{ position: 'relative' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h5" component="div">
                                <Link
                                    to={`/account/menumaker/${menu.id}`}
                                    style={{ textDecoration: 'none', color: '#006d26', fontWeight: 'bold' }}
                                    className="recipes-title"
                                    rel="noopener noreferrer"
                                >
                                    {menu.name}
                                </Link>
                            </Typography>
                            <PopupConfirm
                                trigger={
                                    <IconButton style={{ position: 'absolute', top: 0, right: 0 }}>
                                        <TiDeleteOutline style={{ width: 25, color: "red" }} />
                                    </IconButton>
                                }
                                onConfirm={() => handleConfirmDelete(menu.id)}
                                message={`Êtes-vous sûr de vouloir supprimer le menu : ${menu.name} ?`}
                            />
                        </div>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Statut: <Chip size="small" label={menu.status} />
                        </Typography>
                        <Typography variant="body2">
                            Date de création: {dayjs(menu.createdAt).format('DD/MM/YYYY')}
                        </Typography>
                        {/* Add other menu details as needed */}
                        <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
                            <IconButton>
                                <ShareIcon style={{ width: 15 }} onClick={() => handleShareClick(menu.id)} />
                            </IconButton>
                        </div>
                    </CardContent>
                </Card>
            ));
    };



    return (
        <>
            {/* Search Input */}
            <input
                placeholder="Rechercher"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ margin: '10px', width: '95%', backgroundColor: "transparent", padding: "1rem", border: "1px solid #D5D5D5", borderRadius: "5px" }}
            />

            {/* Add New Menu Button */}
            <div style={{ textAlign: "center", marginRight: ".5rem", marginTop: "2rem", marginBottom: "1rem" }}>
                <PopupAddMenu
                    trigger={
                        <ButtonComponent
                            className="btn-add btn-add-product-table"
                            type="button"
                            text="+ Ajouter un menu"
                            isOpen={isCreatePopupOpen}
                        />
                    }
                    onClose={() => setIsCreatePopupOpen(false)}
                    onCreate={handleCreateMenu}
                    title="Ajouter un menu"
                    message="Message de confirmation"
                />
            </div>

            {/* Menu Cards */}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {renderMenuCards()}
            </div>


            <div className="pagination">
                <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px', marginBottom: "1rem" }}>
                    <Pagination
                        size="small"
                        siblingCount={0}
                        count={menusData.length}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        color="primary"
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </div>
        </>
    );
}
