import "../../views/client-dashboard/ClientDashboard.css"


function ClientSubscription({ userInfo }) {

    return (

        <section className='client-subscription' style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div className='flex-center'>
                <h2 className="client-subscription-title">
                    Explorez de Nouvelles Saveurs en toute simplicité avec le Menu-Maker de Mamossa
                </h2>
            </div>
            <div className='flex-center'>
                <p className="client-subscription-description">Découvrez notre catalogue de recettes et notre concepteur de Menu.
                    Calculez les coûts, apports nutritionnels des recettes, prévoyez les listes de courses de vos menus, découvrez de nouvelles recettes et partagez vos créations !<br /><br />
                    Abonnez-vous à une des formules pour simplifier votre cuisine avec le Menu Maker de Mamossa. <br /><br />Libérerez votre créativité culinaire dès maintenant !</p>
            </div>
            <div className='stripe-pricing'>
                <stripe-pricing-table
                    pricing-table-id="prctbl_1PGLNoLn6islyFl10iHKSc58"
                    publishable-key="pk_live_E27q31wJqPtkHNEoQzSJya8200AoUbm2JJ"
                    client-reference-id={userInfo ? userInfo?.id : ''}
                    customer-email={userInfo ? userInfo?.email : ''}
                >
                </stripe-pricing-table>
            </div>

        </section>


    );
}

export default ClientSubscription;