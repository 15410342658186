function GroupOrderConditions({ groupOrderConditionsChecked, setgroupOrderConditionsCheckedIsChecked }) {

    return (
        <div className='order-condition-container'>
            <div className="order-condition-input">
                <input
                    id="checkbox"
                    type="checkbox"
                    className="order-input-checkbox"
                    checked={groupOrderConditionsChecked}
                    onChange={() => setgroupOrderConditionsCheckedIsChecked(!groupOrderConditionsChecked)}
                />
            </div>
            <div className="order-condition-text">
                <span style={{ fontWeight: 600 }}>Vous participez à une commande groupée. </span>
                Vous n'êtes malheureusement pas dans la zone de livraison immédiate.<br />

                Pour profiter des bons petits plats Mamossa partout en France, vous avez choisi de participer à une commande groupée.<br />

                Dès que le nombre de participants aura atteint 15 personnes, vous pourrez recevoir vos plats directement à l'adresse de livraison indiquée.<br />
                L'équipe Mamossa vous tiendra au courant par mail de l'évolution de votre commande.<br />
                Votre commande peut être conservée entre 24 et 72h au frigo avant d'être consommée.<br />
                Pour toute question, n'hésitez pas à contacter <a className='link-underline' href="mailto:hello@mamossa.com" target="_blank" rel="noreferrer"> hello@mamossa.com</a>  🔥<br />
                🔥
            </div>

        </div>


    );
}

export default GroupOrderConditions;