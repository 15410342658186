import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Typography, Checkbox } from "@mui/material";
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { getRecipesByMenuId } from '../../../api/menuRecipesAPI';

function ShoppingListTables({ productsInfos, menuInfos, updateShoppingListProduct }) {
    const [recipesPortions, setRecipesPortions] = useState({});



    useEffect(() => {
        const fetchRecipePortions = async () => {
            try {
                const recipes = await getRecipesByMenuId(menuInfos.id);
                const recipesPortionsResult = recipes.reduce((acc, recipe) => {
                    acc[recipe.recipe_id] = recipe.portions;
                    return acc;
                }, {});
                setRecipesPortions(recipesPortionsResult);
            } catch (error) {
                console.error('Error fetching recipes for menuId', menuInfos.id, ':', error);
            }
        };

        if (menuInfos.id) {
            fetchRecipePortions();
        }
    }, [menuInfos.id]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004618',
            color: theme.palette.common.white,
            fontWeight: 'bold'
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    // Group products by category
    const groupedProducts = productsInfos.reduce((acc, product) => {
        if (!acc[product.category]) {
            acc[product.category] = [];
        }
        acc[product.category].push(product);
        return acc;
    }, {});

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer>
                <Table stickyHeader aria-label="shopping list table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Acheté</StyledTableCell>
                            <StyledTableCell>Nom du Produit</StyledTableCell>
                            <StyledTableCell align="right">Quantité ajustée</StyledTableCell>
                            <StyledTableCell align="right">Unités</StyledTableCell>
                            <StyledTableCell align="right">Prix/kg(€)</StyledTableCell>
                            <StyledTableCell align="right">Coût/portion (€)</StyledTableCell>
                            <StyledTableCell align="right">Coût (€)</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(groupedProducts).map(([category, products]) => (
                            <React.Fragment key={category}>
                                <TableRow>
                                    <StyledTableCell colSpan={7} style={{ textAlign: 'left', backgroundColor: '#017529', color: '#fff' }}>
                                        {category}
                                    </StyledTableCell>
                                </TableRow>
                                {products.map((product, index) => {
                                    const adjustedUnitQty = (product.unitQty / 4) * (recipesPortions[product.recipesId] || 1);
                                    return (
                                        <TableRow key={index}>
                                            <StyledTableCell padding="checkbox">
                                                <Checkbox
                                                    checked={product.tobuy === false}
                                                    onChange={() => updateShoppingListProduct(product.id)}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {product.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {adjustedUnitQty}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {product.unitType}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {product.price}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {(((product.price * adjustedUnitQty) / 1000) / 4).toFixed(2)}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                {((((product.price * adjustedUnitQty) / 1000) / 4) * menuInfos.portions).toFixed(2)}
                                            </StyledTableCell>
                                        </TableRow>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default ShoppingListTables;
