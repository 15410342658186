import { useState, useEffect } from 'react';
import RecipeCard from './RecipeCard';
import { useParts, fetchAllRecipePictures, synchFridgeQuantity } from '../../api/recipesAPI';

function RecipesList() {
    const [pictures, setPictures] = useState([]);

    useEffect(() => {
        const getPictures = async () => {
            const pics = await fetchAllRecipePictures();
            setPictures(pics);
        };

        const synchronizeQuantities = async () => {
            try {
                await synchFridgeQuantity();

            } catch (err) {
                console.error("Failed to synchronize quantities", err);
            }
        }

        getPictures();
        synchronizeQuantities();

    }, []);

    const { parts, loading, error } = useParts();

    if (parts.length === 0) return null;

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading parts and recipes. Please try again.</div>;

    return (
        <div className='parts-list'>
            {parts.map(part => (
                <section key={part.id} id={part.name}>
                    <div className="parts-list-title-score">
                        <h2 className='part-title'>{part.name}</h2>
                    </div>

                    <div className='recipes-list'>
                        {part.recipes.map(recipe => {
                            const recipePictures = pictures.filter(pic => pic.recipe_id === recipe.id);
                            return (
                                <RecipeCard
                                    key={recipe.id}
                                    recipe={{ ...recipe, pictures: recipePictures }}
                                />
                            );
                        })}
                    </div>
                </section>
            ))}
        </div>
    );
}

export default RecipesList;
