import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';


//Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//Redux
import { setRoles, login } from '../../features/auth/authSlice';

//Elements
import Button from '../../components/elements/button/Button';

//Styles
import './Login.css';

function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Global states
    const { error } = useSelector(state => state.auth);

    //Local States
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    /**
     * Handles the submission of the login form.
     * @param {Event} e - The form submission event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const body = { email, password };

            dispatch(login(body))
                .unwrap()
                .then((originalPromiseResult) => {
                    toast.success('Vous êtes maintenant connecté');
                    navigate("/home");
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.error(rejectedValueOrSerializedError);

                    toast.error("Combinaison e-mail/mot de passe incorrecte. Veuillez réessayer.");

                });
        } catch (err) {
            console.error(err.message);
            toast.error(err.message);
        }
    };

    return (
        <div className="login-container">
            <h1>Connexion</h1>
            <form onSubmit={handleSubmit}>
                <div className='input-content'>
                    <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} required />
                </div>
                <div className='input-content'>
                    <div className="password-container">
                        <input className="password-input" type={showPassword ? "text" : "password"} placeholder="Mot de passe" value={password} onChange={e => setPassword(e.target.value)} required />
                        <button
                            type="button"
                            className="password-toggle-btn"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <VisibilityOffIcon size={10} style={{ color: "#D5D5D5" }} /> : <VisibilityIcon size={10} style={{ color: "#D5D5D5" }} />}
                        </button>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                </div>

                <div className='input-content'>
                    <Button className="btn-full" type="submit" text="Se connecter" />
                </div>
            </form>
            <div className="forgotten-password">
                <Link to="/reset-password">Mot de passe oublié</Link>
            </div>
            <div className="create-account-link">
                <p>Pas encore de compte ?</p>
                <Link to="/signup">Créer un compte</Link>
            </div>
        </div>
    );
}

export default Login;