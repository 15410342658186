import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

// MUI
import { Button } from "@mui/material";

// Styles
import './RecipesInfos.css';

//Toast
import toast from 'react-hot-toast';

// API
import { fetchAllRecipePictures, fetchRecipeById, deleteRecipeById } from "../../../../api/recipesAPI";
import { fetchNutriFacts } from '../../../../api/nutriFactsAPI'

// Components
import RecipesDetails from '../../../../components/admin-dashboard/recipes/RecipesDetails';
import RecipeProducts from '../../../../components/admin-dashboard/recipes/RecipeProducts';
import NutritionFacts from '../../../../components/admin-dashboard/recipes/NutritionFacts';
import PopupConfirm from '../../../../components/elements/popup/popup-confirm/PopupConfirm';

function RecipesInfos() {
    const [pictures, setPictures] = useState([]);
    const [shareLink, setShareLink] = useState("");
    const [showLink, setShowLink] = useState(false);
    const [nutriFacts, setNutriFacts] = useState([]);
    const [recipeDetails, setRecipeDetails] = useState([]);
    const [error, setError] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        const fetchNutriInfos = async () => {
            try {
                const data = await fetchNutriFacts(id);
                setNutriFacts(data);
            } catch (error) {
                setError(error);
            }
        };

        fetchNutriInfos();
    }, [id]);

    useEffect(() => {
        const getPictures = async () => {
            try {
                const pics = await fetchAllRecipePictures();
                setPictures(pics);
            } catch (error) {
                console.error("Erreur lors de la récupération des images des recettes:", error);
            }
        };

        getPictures();
    }, []);

    useEffect(() => {
        // Mettre à jour `shareLink` à chaque fois que l'ID change
        const newShareLink = `${window.location.origin}/recipes/${id}/shared`;
        setShareLink(newShareLink);
    }, [id]);

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareLink);
            toast.success("Lien copié dans le presse-papier");
        } catch (error) {
            console.error("Erreur lors de la copie du lien :", error);
            toast.error("Erreur lors de la génération du lien de partage");
        }
    };

    const handleShareClick = () => {
        setShowLink(true);
        copyToClipboard();
    };

    const handleDeleteRecipe = async () => {
        try {
            await deleteRecipeById(id);
            window.close();
        } catch (error) {
            console.error("Erreur lors de la suppression de la recette :", error);
            setError(error);
        }
    };

    return (
        <>
            <div className="share-recipe flex-center">
                <Button variant="outlined" size="small" color="success" sx={{ width: "300px" }} onClick={handleShareClick}>Partager</Button>

                {showLink && (
                    <input type="text" value={shareLink} readOnly />
                )}
            </div>

            <div className="recipes-infos">
                <RecipesDetails recipeId={id} pictures={pictures} nutriFacts={nutriFacts} />
            </div>
            <div className="recipes-infos-products">
                <RecipeProducts recipeId={id} />
            </div>
            <div className="recipes-infos-nutri-facts">
                <NutritionFacts recipeId={id} />
            </div>

            <div className="recipes-infos-action flex-center">
                <PopupConfirm
                    trigger={<Button variant="outlined" color="error" >Supprimer la recette</Button>}
                    onConfirm={handleDeleteRecipe}
                    message={`Êtes-vous sûr de vouloir supprimer la recette ?`}
                />
            </div>
        </>
    );
}

export default RecipesInfos;
