import React, { useState, useEffect } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, updateCart } from '../../features/cart/cartSlice';
import { setPreorderEnabled } from '../../features/preorder/preorderSlice';

//Toast
import toast from 'react-hot-toast';

//MUI components
import Chip from '@mui/joy/Chip';
import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Input from '@mui/joy/Input';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//API
import { fetchNutriFacts } from '../../api/nutriFactsAPI';
import { fetchProductByRecipeId } from '../../api/stockAPI';

//Images
import NutriA from '../../assets/nutri-score/NutriscoreA.png';
import NutriB from '../../assets/nutri-score/NutriscoreB.png';
import NutriC from '../../assets/nutri-score/NutriscoreC.png';
import NutriD from '../../assets/nutri-score/NutriscoreD.png';
import NutriE from '../../assets/nutri-score/NutriscoreE.png';

//Icons
import { IoIosInformationCircleOutline } from "react-icons/io";

// Components
import Button from '../elements/button/Button';
import PopupRecipeInfos from '../elements/popup/popup-recipe/PopupRecipeInfos';

function RecipeCard({ recipe }) {
    const preorderEnabled = useSelector((state) => state.preorder.preorderEnabled);
    const [nutriFacts, setNutriFacts] = useState([]);
    const [ingredients, setIngredients] = useState({});
    const [error, setError] = useState(null);
    const [openIngredientsPopup, setOpenIngredientsPopup] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    const deliveryZones = ['93220', '93340', '93250', '93370', '93330'];
    const userPostalCode = sessionStorage?.getItem('userPostalCode');
    const isInDeliveryZone = deliveryZones.includes(userPostalCode);


    const recipeId = recipe.id;

    const fetchNutriInfos = async () => {
        try {
            const data = await fetchNutriFacts(recipeId);
            setNutriFacts(data);
        } catch (error) {
            setError(error);
        }
    };

    const fetchIngredients = async () => {
        try {
            const ingredients = await fetchProductByRecipeId(recipeId);
            setIngredients(ingredients);
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        fetchNutriInfos(recipeId);
    }, [recipeId]);

    useEffect(() => {
        fetchIngredients(recipeId);
    }, [recipeId]);

    let nutriScoreImage;

    switch (nutriFacts?.nutriscore) {
        case 'A':
            nutriScoreImage = <img src={NutriA} alt="NutriA" />;
            break;
        case 'B':
            nutriScoreImage = <img src={NutriB} alt="NutriB" />;
            break;
        case 'C':
            nutriScoreImage = <img src={NutriC} alt="NutriC" />;
            break;
        case 'D':
            nutriScoreImage = <img src={NutriD} alt="NutriD" />;
            break;
        case 'E':
            nutriScoreImage = <img src={NutriE} alt="NutriE" />;
            break;
        default:
            nutriScoreImage = null;
    }

    const buttonText = preorderEnabled
        ? "Pré-commander"
        : !isInDeliveryZone
            ? "Commander"
            : recipe.qty_available === 0
                ? "Revient bientôt"
                : "Ajouter au panier";




    const imageUrl =
        recipe && recipe.pictures && recipe.pictures.length > 0
            ? `${process.env.REACT_APP_API_URL}${recipe.pictures[0].path}`
            : undefined;

    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.items);

    const notify = () => toast.success(`${recipe.name} a bien été ajouté au panier`);

    const handleAddToCart = () => {
        if (isInDeliveryZone && !preorderEnabled && recipe.qty_available === 0) {
            toast.error(`${recipe.name} n'est pas disponible actuellement`);
            return;
        }

        if (!preorderEnabled && isInDeliveryZone) {
            dispatch(updateCart());
        }

        const isProductInCart = cartItems.some((item) => item.id === recipe.id);
        const productQuantityInCart =
            cartItems.find((item) => item.id === recipe.id)?.quantity || 0;

        if (
            !isInDeliveryZone ||
            productQuantityInCart < recipe.qty_available ||
            (preorderEnabled && recipe.qty_available === 0)
        ) {
            dispatch(addToCart(recipe));
            notify();
        } else {
            toast.error(`La quantité maximale de ${recipe.name} est atteinte`);
        }
    };

    const handleInfosClick = () => {
        setOpenIngredientsPopup(true);
    };

    return (
        <div className="card">
            <>
                <Card sx={{ width: isMobile ? '330px' : isTablet ? 330 : 420, padding: 0, height: isMobile ? "100%" : 480 }} orientation="vertical">
                    <AspectRatio ratio="16/9" sx={{ width: "100%", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt={recipe.name}
                                className="card-img"
                                onError={() => console.error(`Erreur lors du chargement de l'image pour la recette ${recipe.name}`)}
                            />
                        ) : (
                            <span>Aucune image disponible</span>
                        )}
                    </AspectRatio>
                    <CardContent orientation="vertical" sx={{ display: "flex", justifyContent: "space-between", paddingLeft: 2, paddingRight: 2 }}>
                        <div className='card-title-mobile'>
                            <div>
                                <Typography level="title-lg">
                                    {recipe.name}
                                    <IoIosInformationCircleOutline style={{ fontSize: 14, paddingLeft: 3 }} onClick={() => handleInfosClick()} />
                                </Typography>
                            </div>
                            <div>
                                <Typography fontSize="sm" fontWeight="lg">
                                    {recipe.price + "€"}
                                </Typography>
                            </div>
                        </div>
                        <div>
                            <Typography fontSize="xs" sx={{ textAlign: "justify" }}>
                                {recipe.description.length > 200 ? `${recipe.description.substring(0, 250)}...` : recipe.description}
                            </Typography>
                        </div>
                        <div className="card-title-infos">
                            <div className='card-calories-score'>
                                {nutriFacts &&
                                    <div className="card-calories">
                                        <Chip variant="soft" color="primary">
                                            {parseInt(nutriFacts?.calories / nutriFacts?.portions) + " kcal"}
                                        </Chip>
                                    </div>
                                }
                                <div>{nutriScoreImage}</div>
                            </div>
                        </div>
                    </CardContent>
                    <Button
                        className={recipe.qty_available === 0 && !preorderEnabled && isInDeliveryZone ? "btn-disabled-mobile" : "btn-full-mobile"}
                        text={buttonText}
                        onClick={handleAddToCart}
                        disabled={recipe.qty_available === 0 && !preorderEnabled && isInDeliveryZone}
                    />
                </Card>
            </>
            <PopupRecipeInfos
                open={openIngredientsPopup}
                onClose={() => setOpenIngredientsPopup(false)}
                ingredients={ingredients}
                title={`Informations sur ${recipe.name}`}
                description={recipe.description}
            />
        </div>
    );
}

export default RecipeCard;
