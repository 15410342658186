import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [],
  totalTTC: localStorage.getItem("cart")
    ? calculateTotalTTC(JSON.parse(localStorage.getItem("cart")))
    : 0,
};

function calculateTotalTTC(items) {
  return (
    items.reduce((acc, item) => acc + item.price * 100 * item.quantity, 0) / 100
  );
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );

      if (itemIndex !== -1) {
        state.items[itemIndex].quantity += 1;
      } else {
  
          state.items.push({ ...action.payload, quantity: 1 });
        
      }

      state.totalTTC = calculateTotalTTC(state.items);
      localStorage.setItem("cart", JSON.stringify(state.items));
    },
    removeFromCart: (state, action) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.items.splice(index, 1);
        state.totalTTC = calculateTotalTTC(state.items);
        localStorage.setItem("cart", JSON.stringify(state.items));
      }
    },
    incrementQuantity: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (itemIndex !== -1) {
        state.items[itemIndex].quantity += 1;
        state.totalTTC = calculateTotalTTC(state.items);
        localStorage.setItem("cart", JSON.stringify(state.items));
      }
    },
    decrementQuantity: (state, action) => {
      const itemIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (itemIndex !== -1 && state.items[itemIndex].quantity > 1) {
        state.items[itemIndex].quantity -= 1;
        state.totalTTC = calculateTotalTTC(state.items);
        localStorage.setItem("cart", JSON.stringify(state.items));
      }
    },
    clearUnavailableItems: (state) => {
      // Filtrer les éléments indisponibles et mettre à jour le panier
      state.items = state.items.filter((item) => item.qty_available > 0);
      state.totalTTC = calculateTotalTTC(state.items);
      localStorage.setItem("cart", JSON.stringify(state.items));
    },
    updateCart: (state) => {
      // Filtrer les éléments indisponibles et mettre à jour le panier
      state.items = state.items.filter((item) => item.qty_available > 0);
      state.totalTTC = calculateTotalTTC(state.items);
      localStorage.setItem("cart", JSON.stringify(state.items));
    },
    resetCart: (state) => {
      // Réinitialisez le panier à son état initial (vide)
      state.items = [];
      state.totalTTC = 0;
      localStorage.removeItem("cart"); // Supprimez également les données du panier dans le local storage
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  resetCart,
  clearUnavailableItems,
  updateCart,
} = cartSlice.actions;
export default cartSlice.reducer;
