import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Badge, styled, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Button } from "@mui/material";
import Chip from '@mui/joy/Chip';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PopupConfirm from '../../elements/popup/popup-confirm/PopupConfirm';
import { fetchProduct, deleteProductById } from "../../../api/stockAPI";
import toast from 'react-hot-toast';
import PopupEditProduct from "../../elements/popup/popup-product/PopupEditProduct";
import PopupCreate from "../../elements/popup/popup-product/PopupCreate";
import ButtonComponent from "../../elements/button/Button";
import { CiEdit } from 'react-icons/ci';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createProduct } from "../../../api/stockAPI";
import { linkProductToAllergy } from "../../../api/productAllergyAPI"

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    marginBottom: "0rem",
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    color: theme.palette.common.black,
    fontWeight: "bold",
    cursor: "pointer",
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
}));

const MobileProduct = () => {
    const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 1024px)");

    const [products, setProducts] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState("");
    const productsPerPage = isTablet ? 12 : 7;
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        const getAllProducts = async () => {
            try {
                const fetchedProducts = await fetchProduct();
                setProducts(fetchedProducts);
            } catch (error) {
                console.error("Erreur lors de la récupération des produits:", error);
            }
        };

        getAllProducts();
    }, []);

    useEffect(() => {
        const productsToSort = [...products];
        productsToSort.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setSortedProducts(productsToSort);
    }, [sortConfig, products]);

    const handleSort = useCallback(key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }, [sortConfig]);

    const handleNextPage = useCallback(() => {
        if (currentPage < Math.ceil(products.length / productsPerPage)) {
            setCurrentPage(prev => prev + 1);
        }
    }, [currentPage, products, productsPerPage]);

    const handlePreviousPage = useCallback(() => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    }, [currentPage]);

    // Create new product in DB
    const handleCreateProduct = async (formData, selectedAllergen) => {
        try {
            const newProductResponse = await createProduct(formData);

            if (newProductResponse && newProductResponse.id) {
                toast.success('Produit créé avec succès');
                setIsPopupOpen(false);
                setProducts((prevProducts) => [...prevProducts, newProductResponse]);

                // Appel de linkProductToAllergy après la création du produit
                if (selectedAllergen) {
                    const productAllergyData = {
                        productId: newProductResponse.id,
                        allergyId: selectedAllergen
                    }
                    await linkProductToAllergy(productAllergyData);
                    toast.success('Allergène lié au produit avec succès');
                }

                // Après avoir ajouté le nouveau produit, récupérez à nouveau les produits
                fetchProduct();
            } else {
                toast.error('Erreur lors de la création du produit');
            }
        } catch (error) {
            toast.error('Erreur lors de la création du produit');
            console.error("Erreur lors de la création du produit", error);
        }
    };

    const handleConfirmDelete = useCallback(async (productId) => {
        try {
            await deleteProductById(productId);
            toast.success('Produit supprimée avec succès');
            const updatedProducts = products.filter(product => product.id !== productId);
            setProducts(updatedProducts);
        } catch (error) {
            toast.error('Erreur lors de la suppression du produit');
        }
        fetchProduct();
    }, [products]);

    const filteredProducts = useMemo(() => sortedProducts.filter(product =>
        (product.id?.toString() || "").includes(searchTerm) ||
        (product.name?.toString() || "").includes(searchTerm) ||
        (product.category?.toString() || "").includes(searchTerm) ||
        (product.label?.toString() || "").includes(searchTerm)
    ), [sortedProducts, searchTerm]);

    const getColorAndEmoji = (product) => {
        switch (product.label) {
            case "bio":
                return { sx: { bgcolor: "#CFFFCE", color: "#3B3636" }, emoji: "🌿" };
            case "national":
                return { sx: { bgcolor: "#D7EAFF", color: "#3B3636" }, emoji: "🇫🇷" };
            case "local":
                return { sx: { bgcolor: "#D2F1F3", color: "#3B3636" }, emoji: "🏡" };
            default:
                return { sx: {}, emoji: "" };
        }
    };

    const handleEditClick = (product) => {
        setSelectedProduct(product);
        setOpenEditPopup(true);
    };

    const handleSaveProduct = useCallback((updatedProduct) => {
        const updatedProducts = products.map(product =>
            product.id === updatedProduct.id ? updatedProduct : product
        );
        setProducts(updatedProducts);
        setOpenEditPopup(false);
    }, [products]);



    return (
        <div className="orders-table">
            <div className="orders-table-title flex-center">
                <h1 style={{ margin: "1rem 0" }}>Liste des produits</h1>
            </div>
            <div className="flex-center" style={{ "display": "flex", "marginBottom": "3rem" }}>
                <input
                    type="text"
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    style={{ "width": 300, "padding": ".85rem", "marginRight": "-1rem", borderTop: "none", borderRight: "none", borderLeft: "none", backgroundColor: "transparent", outline: "none" }}
                />
            </div>
            <div style={{ textAlign: "right", marginRight: ".8rem" }}>
                <PopupCreate
                    trigger={
                        <ButtonComponent
                            className="btn-add btn-add-product-table"
                            type="button"
                            text="+ Ajouter un produit"
                            isOpen={isPopupOpen}

                        />
                    }

                    onClose={() => setIsPopupOpen(false)}
                    onCreate={handleCreateProduct}
                    title="Ajouter un produit"
                    message="Message de confirmation"
                />
            </div>
            {filteredProducts.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage).map(product => (
                <StyledAccordion key={product.id}>
                    <StyledAccordionSummary sx={{ width: isTablet ? 500 : 300 }}>
                        <IconButton size="small" onClick={() => handleSort(product.name)} style={{ color: 'rgb(50, 89, 62)', fontSize: 16 }}>
                            <Typography level="body-ms" color="neutral">

                                <Chip sx={getColorAndEmoji(product).sx}>
                                    {product.name.toUpperCase()}
                                    {product.label && (
                                        <>
                                            {" — " + product.label} {getColorAndEmoji(product).emoji}
                                        </>
                                    )}
                                </Chip>
                                {sortConfig.key === 'product.label' && (sortConfig.direction === 'ascending' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
                            </Typography>
                        </IconButton>
                        <IconButton style={{ marginLeft: "2rem" }}>
                            <Typography level="body-ms" color="neutral">
                                {product.price + `€`}
                            </Typography>
                        </IconButton>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Typography variant="outlined" color="neutral" marginLeft={'1.5rem'}>Stock — <b>{product.totalQuantity}</b></Typography>
                        <Typography style={{ display: "flex", alignItems: "center", marginTop: "1rem", justifyContent: "space-between" }}>
                            <IconButton style={{ fontSize: 14 }} onClick={() => handleEditClick(product)}>
                                Modifier le produit
                            </IconButton>

                            <PopupEditProduct
                                open={openEditPopup}
                                onClose={() => setOpenEditPopup(false)}
                                data={selectedProduct}
                                onSave={handleSaveProduct}
                            />
                            <PopupConfirm
                                trigger={<IconButton style={{ fontSize: 14, color: "red" }}>Supprimer le produit</IconButton>}
                                onConfirm={() => handleConfirmDelete(product.id)}
                                message={`Êtes-vous sûr de vouloir supprimer ${product.name} ?`}
                            />
                        </Typography>
                    </StyledAccordionDetails>
                </StyledAccordion>
            ))}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "3rem" }}>
                <Button style={{ border: "green 1px solid", color: "green" }} disabled={currentPage === 1} onClick={handlePreviousPage}>
                    <ArrowBackIosNewIcon />
                </Button>
                <span>Page {currentPage}</span>
                <Button style={{ border: "green 1px solid", color: "green" }} disabled={currentPage === Math.ceil(products.length / productsPerPage)} onClick={handleNextPage}>
                    <ArrowForwardIosIcon />
                </Button>
            </div>
        </div>
    );
}

export default MobileProduct;
