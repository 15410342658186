import { useState, useEffect } from "react";

// Icons
import { GoPlus } from "react-icons/go";

// Toast
import toast from 'react-hot-toast';

// API
import { updateRecipeImage } from "../../../api/recipesAPI";

function RecipeImage({ imageUrl, recipeId, onImageUpdated, recipe }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageName, setSelectedImageName] = useState("");
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.id) {
            setCurrentUser(user.id);
        }
    }, []);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        setSelectedImageName(file ? file.name : "");
    };

    const handleImageUpdate = async () => {
        try {
            if (!selectedImage) {
                console.error("Veuillez sélectionner une image");
                return;
            }

            await updateRecipeImage(recipeId, selectedImage);

            if (onImageUpdated) {
                onImageUpdated();
            }

            toast.success('Photo modifiée avec succès');
        } catch (error) {
            console.error("Error updating image:", error);
        }
    };

    const isOwner = currentUser === recipe.user_id;

    return (
        <>
            {isOwner && (
                <div className="recipes-details-img-actions">
                    <label htmlFor="imageInput" className="btn-add">
                        <GoPlus style={{ width: 15, color: "green" }} /> Modifier la photo
                    </label>
                    <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                    />
                    {selectedImageName && (
                        <>
                            <div>
                                <span style={{ fontWeight: "bold" }}>Image sélectionnée :</span> {selectedImageName}
                            </div>
                            <button style={{ marginLeft: "1rem" }} className="btn-update btn-add" onClick={handleImageUpdate}>
                                Valider
                            </button>
                        </>
                    )}
                </div>
            )}
            <div className="flex-center">
                <div className="recipes-details-img-container">
                    {imageUrl && <img src={imageUrl} alt="recipes-details" className="recipes-details-img" />}
                </div>
            </div>
        </>
    );
}

export default RecipeImage;
